export const internalUsers = [
  "Super Admin",
  "Admin",
  "Accountant",
  "Representative",
  "Animator",
  "Agent",
  "Project Manager",
];
export const externalUsers = ["Employee", "Manager"];

export const allTypes = [
  "Super Admin",
  "Admin",
  "Accountant",
  "Representative",
  "Animator",
  "Agent",
  "Project Manager",
  "Employee",
  "Manager",
];

export const userTypes_Ids = [
  {
    label: "Super Admin",
    value: "1",
  },
  {
    label: "Admin",
    value: "2",
  },
  {
    label: "Representative",
    value: "3",
  },
  {
    label: "Accountant",
    value: "4",
  },
  {
    label: "Project Manager",
    value: "5",
  },
  {
    label: "Agent",
    value: "6",
  },
  {
    label: "Animator",
    value: "7",
  },
  {
    label: "Manager",
    value: "8",
  },
  {
    label: "Employee",
    value: "9",
  },
];

