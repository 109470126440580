import { useState } from "react";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";

const ManageNotification = ({setIsLoading}) => {
  const { language } = useLanguage();
  const [preparationSheet, setPreparationSheet] = useState([
    "jlaplante@fidgi.ca",
    "kpion@fidgi.ca",
    "acoutu@fidgi.ca",
    "ivinet@fidgi.ca",
  ]);
  const [callCenterList, setCallCenterList] = useState([
    "jlaplante@fidgi.ca",
    "kpion@fidgi.ca",
    "	sromanini@fidgi.ca",
    "acoutu@fidgi.ca",
  ]);
  const [graphList, setGraphList] = useState([
    "jlaplante@fidgi.ca",
    "kpion@fidgi.ca",
    "	sromanini@fidgi.ca",
    "acoutu@fidgi.ca",
  ]);
  const [clientSheet, setClientSheet] = useState([
    "jlaplante@fidgi.ca",
    "kpion@fidgi.ca",
    "	sromanini@fidgi.ca",
    "acoutu@fidgi.ca",
  ]);
  return (
    <div className="manage-notification">
      <div class="container maincont">
        <div id="app" class="appcountainer">
          <div class="edit-form-form">
            <nav class="navbar navbar-light">
              <div>
                <h2>{translations.notificationsPage.title[language]} </h2>
              </div>
            </nav>
            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <h5 style={{ textAlign: "center" }}>
                    {translations.notificationsPage.preparationSheet[language]}
                  </h5>
                  <table class="table table-hover text-center align-middle">
                    <thead>
                      <tr>
                        <th scope="col">
                          {translations.commonFields.email[language]}
                        </th>
                        <th scope="col">
                          {translations.commonFields.delete[language]}
                        </th>
                      </tr>
                    </thead>
                    <tbody id="prepSheetEmailList" class="result-sec">
                      {preparationSheet.map((item) => {
                        return (
                          <tr
                            class="result-box"
                            id="result-box" /*data="jlaplante@fidgi.ca"*/
                          >
                            <td class="email-entry" /*id="jlaplante@fidgi.ca"*/>
                              {item}
                            </td>
                            <td>
                              <button
                                type="button"
                                class="btn btn-danger delete-email-notif-button"
                                fdprocessedid="bpgya"
                              >
                                {translations.commonFields.delete[language]}
                              </button>
                            </td>
                          </tr>
                        );
                      })}

                      {/* <tr class="result-box" id="result-box" data="kpion@fidgi.ca">
                      <td class="email-entry" id="kpion@fidgi.ca">kpion@fidgi.ca</td>
                      <td><button type="button" class="btn btn-danger delete-email-notif-button" fdprocessedid="j4mx0g9">Delete</button></td>
                    </tr>
                    <tr class="result-box" id="result-box" data="acoutu@fidgi.ca ">
                      <td class="email-entry" id="acoutu@fidgi.ca ">acoutu@fidgi.ca </td>
                      <td><button type="button" class="btn btn-danger delete-email-notif-button" fdprocessedid="8ah2ytl">Delete</button></td>
                    </tr>
                    <tr class="result-box" id="result-box" data="ivinet@fidgi.ca">
                      <td class="email-entry" id="ivinet@fidgi.ca">ivinet@fidgi.ca</td>
                      <td><button type="button" class="btn btn-danger delete-email-notif-button" fdprocessedid="lii5dg">Delete</button></td>
                    </tr> */}
                    </tbody>
                  </table>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder={translations.commonFields.email[language]}
                      aria-label="Recipient's username"
                      aria-describedby="prepSheetEmailListAdd"
                      id="prepSheetEmailListAddInput"
                      fdprocessedid="p517q6"
                    />
                    <button
                      class="client-btn btn-success add-button-notif"
                      type="button"
                      id="prepSheetEmailList"
                      fdprocessedid="u39qc4"
                    >
                      {translations.commonFields.submitBtn[language]}
                    </button>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="mb-3">
                  <h5 style={{ textAlign: "center" }}>
                    {translations.notificationsPage.callCenter[language]}
                  </h5>
                  <table class="table table-hover text-center align-middle">
                    <thead>
                      <tr>
                        <th scope="col">
                          {translations.commonFields.email[language]}
                        </th>
                        <th scope="col">
                          {translations.commonFields.delete[language]}
                        </th>
                      </tr>
                    </thead>
                    <tbody id="callCenterEmailList" class="result-sec">
                      {callCenterList.map((item) => {
                        return (
                          <tr
                            class="result-box"
                            id="result-box" /* data="jlaplante@fidgi.ca"*/
                          >
                            <td
                              class="email-entry" /* id="jlaplante@fidgi.ca"*/
                            >
                              {item}
                            </td>
                            <td>
                              <button
                                type="button"
                                class="btn btn-danger delete-email-notif-button"
                                fdprocessedid="rfekws"
                              >
                                {translations.commonFields.delete[language]}
                              </button>
                            </td>
                          </tr>
                        );
                      })}

                      {/* <tr class="result-box" id="result-box" data="kpion@fidgi.ca">
                      <td class="email-entry" id="kpion@fidgi.ca">kpion@fidgi.ca</td>
                      <td><button type="button" class="btn btn-danger delete-email-notif-button" fdprocessedid="gmste">Delete</button></td>
                    </tr>
                    <tr class="result-box" id="result-box" data="sromanini@fidgi.ca">
                      <td class="email-entry" id="sromanini@fidgi.ca">sromanini@fidgi.ca</td>
                      <td><button type="button" class="btn btn-danger delete-email-notif-button" fdprocessedid="8b710t">Delete</button></td>
                    </tr>
                    <tr class="result-box" id="result-box" data="acoutu@fidgi.ca ">
                      <td class="email-entry" id="acoutu@fidgi.ca ">acoutu@fidgi.ca </td>
                      <td><button type="button" class="btn btn-danger delete-email-notif-button" fdprocessedid="kpoorp">Delete</button></td>
                    </tr> */}
                    </tbody>
                  </table>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder={translations.commonFields.email[language]}
                      aria-label="Recipient's username"
                      aria-describedby="callCenterEmailListAdd"
                      id="callCenterEmailListAddInput"
                      fdprocessedid="8zzrop"
                    />
                    <button
                      class="client-btn btn-success add-button-notif"
                      type="button"
                      id="callCenterEmailList"
                      fdprocessedid="jgxby"
                    >
                      {translations.commonFields.submitBtn[language]}
                    </button>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="mb-3">
                  <h5 style={{ textAlign: "center" }}>
                    {translations.notificationsPage.Graph[language]}
                  </h5>
                  <table class="table table-hover text-center align-middle">
                    <thead>
                      <tr>
                        <th scope="col">
                          {translations.commonFields.email[language]}
                        </th>
                        <th scope="col">
                          {translations.commonFields.delete[language]}
                        </th>
                      </tr>
                    </thead>
                    <tbody id="graphSheetEmailList" class="result-sec">
                      {graphList.map((item) => {
                        return (
                          <tr
                            class="result-box"
                            id="result-box" /* data="jlaplante@fidgi.ca"*/
                          >
                            <td
                              class="email-entry" /* id="jlaplante@fidgi.ca"*/
                            >
                              {item}
                            </td>
                            <td>
                              <button
                                type="button"
                                class="btn btn-danger delete-email-notif-button"
                                fdprocessedid="wt288i"
                              >
                                {translations.commonFields.delete[language]}
                              </button>
                            </td>
                          </tr>
                        );
                      })}

                      {/* <tr class="result-box" id="result-box" data="kpion@fidgi.ca">
                      <td class="email-entry" id="kpion@fidgi.ca">kpion@fidgi.ca</td>
                      <td><button type="button" class="btn btn-danger delete-email-notif-button" fdprocessedid="ftijvk">Delete</button></td>
                    </tr>
                    <tr class="result-box" id="result-box" data="fpicard@fidgi.ca ">
                      <td class="email-entry" id="fpicard@fidgi.ca ">fpicard@fidgi.ca </td>
                      <td><button type="button" class="btn btn-danger delete-email-notif-button" fdprocessedid="b4wzzr">Delete</button></td>
                    </tr>
                    <tr class="result-box" id="result-box" data="choude@fidgi.ca">
                      <td class="email-entry" id="choude@fidgi.ca">choude@fidgi.ca</td>
                      <td><button type="button" class="btn btn-danger delete-email-notif-button" fdprocessedid="suwu5e">Delete</button></td>
                    </tr> */}
                    </tbody>
                  </table>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder={translations.commonFields.email[language]}
                      aria-label="Recipient's username"
                      aria-describedby="graphSheetEmailListAdd"
                      id="graphSheetEmailListAddInput"
                      fdprocessedid="0y3og"
                    />
                    <button
                      class="client-btn btn-success add-button-notif"
                      type="button"
                      id="graphSheetEmailList"
                      fdprocessedid="eybaz3"
                    >
                      {translations.commonFields.submitBtn[language]}
                    </button>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="mb-3">
                  <h5 style={{ textAlign: "center" }}>
                    {translations.notificationsPage.clientSheet[language]}
                  </h5>
                  <table class="table table-hover text-center align-middle">
                    <thead>
                      <tr>
                        <th scope="col">
                          {translations.commonFields.email[language]}
                        </th>
                        <th scope="col">
                          {translations.commonFields.delete[language]}
                        </th>
                      </tr>
                    </thead>
                    <tbody id="clientSheetEmailList" class="result-sec">
                      {clientSheet.map((item) => {
                        return (
                          <tr
                            class="result-box"
                            id="result-box" /*data="mmazraani@fidgi.ca"*/
                          >
                            <td
                              class="email-entry" /* id="mmazraani@fidgi.ca"*/
                            >
                              {item}
                            </td>
                            <td>
                              <button
                                type="button"
                                class="btn btn-danger delete-email-notif-button"
                                fdprocessedid="6zoaf6"
                              >
                                {translations.commonFields.delete[language]}
                              </button>
                            </td>
                          </tr>
                        );
                      })}

                      {/* <tr class="result-box" id="result-box" data="kpion@fidgi.ca">
                      <td class="email-entry" id="kpion@fidgi.ca">kpion@fidgi.ca</td>
                      <td><button type="button" class="btn btn-danger delete-email-notif-button" fdprocessedid="v7q1lw">Delete</button></td>
                    </tr>
                    <tr class="result-box" id="result-box" data="jlaplante@fidgi.ca">
                      <td class="email-entry" id="jlaplante@fidgi.ca">jlaplante@fidgi.ca</td>
                      <td><button type="button" class="btn btn-danger delete-email-notif-button" fdprocessedid="z7p8mn">Delete</button></td>
                    </tr>
                    <tr class="result-box" id="result-box" data="acoutu@fidgi.ca ">
                      <td class="email-entry" id="acoutu@fidgi.ca ">acoutu@fidgi.ca </td>
                      <td><button type="button" class="btn btn-danger delete-email-notif-button" fdprocessedid="s37zhb">Delete</button></td>
                    </tr> */}
                    </tbody>
                  </table>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder={translations.commonFields.email[language]}
                      aria-label="Recipient's username"
                      aria-describedby="clientSheetEmailListAdd"
                      id="clientSheetEmailListAddInput"
                      fdprocessedid="voiziw"
                    />
                    <button
                      class="client-btn btn-success add-button-notif"
                      type="button"
                      id="clientSheetEmailList"
                      fdprocessedid="w6o7j"
                    >
                      {translations.commonFields.submitBtn[language]}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <template id="rowTemplateNotif">
            <tr class="result-box" id="result-box">
              <td class="email-entry"></td>
              <td><button type="button" class="btn btn-danger delete-email-notif-button">Delete</button></td>
            </tr>
          </template>
          <template id="rowTemplateNotifEmpty">
            <tr class="result-box" id="result-box" style={{ height: "55px !important" }}>
              <td class="email-entry" style={{ height: "55px !important" }}></td>
              <td style={{ height: "55px !important" }}></td>
            </tr>
          </template>

          <div class="position-fixed top-0 end-0 p-3" style={{ zIndex: "10000000" }}>
            <div id="liveToast" class="toast alert-success" role="alert" aria-live="assertive" aria-atomic="true">

              <div class="d-flex">
                <div class="toast-body">
                  🎊 🎉 Changes saved
                </div>
                <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
              </div>
            </div>
            <div id="copieToast" class="toast alert-success" role="alert" aria-live="assertive" aria-atomic="true">

              <div class="d-flex">
                <div class="toast-body">
                  🎊 🎉 Copy successful
                </div>
                <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ManageNotification;
