import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ApiUrl } from "./ApiUrl";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";
import Select from "react-select";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Loader from "./loader";
import Dropzone from "react-dropzone";

const AddDealershipModal = ({
  setDealerTrigger,
  setIsLoading,
  isDarkMode,
  setNewDealership = () => {},
  dealerships,
  setSelectedDealership = () => {},
  users,
  quickAdd = false
}) => {
  const token = localStorage.getItem("token");
  const { language } = useLanguage();
  const [brands, setBrands] = useState();
  const [selected, setSelected] = useState(null);
  const [repOptions, setRepOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  var button = document.getElementById("closebtn3");
  var currentUrl = window.location.href;

  // console.log(currentUrl.includes("events"));
  

  const initialData = {
    name: "",
    country: "",
    state: "",
    city: "",
    address: "",
    postal_code: "",
    timezone: "",
    phone_number: "",
    primary_contact: "",
    group_id: "",
    representative_id: "",
    brand_id: "",
    logo: "",
    crm_link: "",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      translations.quickCreationModal.validationsText.dealershipText[language]
    ),
    logo: Yup.mixed()
      .test(
        "fileType",
        `${translations.quickCreationModal.validationsText.logoformat[language]}`,
        (value) => {
          return (
            value &&
            ["image/jpeg", "image/webp", "image/png", "image/jpg"].includes(
              value.type
            )
          );
        }
      )
      .required(translations.quickCreationModal.validationsText.logo[language]),
    country: Yup.string().required(
      translations.quickCreationModal.validationsText.country[language]
    ),
    state: Yup.string().required(
      translations.quickCreationModal.validationsText.state[language]
    ),
    city: Yup.string().required(
      translations.quickCreationModal.validationsText.city[language]
    ),
    address: Yup.string().required(
      translations.quickCreationModal.validationsText.address[language]
    ),
    postal_code: Yup.number().required(
      translations.forms.validationTranslations.postal_code[language]
    ),
    timezone: Yup.string().required(
      translations.quickCreationModal.validationsText.timezone[language]
    ),
    phone_number: Yup.string().required(
      translations.quickCreationModal.validationsText.phoneNumber[language]
    ),
    primary_contact: Yup.string().required(
      translations.quickCreationModal.validationsText.primaryContact[language]
    ),
    group_id: Yup.string().required(
      translations.quickCreationModal.validationsText.Group[language]
    ),
    representative_id: Yup.string().required(
      translations.forms.validationTranslations.representatives[language]
    ),
    brand_id: Yup.string().required(
      translations.quickCreationModal.validationsText.brandText[language]
    ), //.required('Dealership Name is required'),
    crm_link: Yup.string().required(
      translations.quickCreationModal.validationsText.crmLink[language]
    ),
  });

  useEffect(() => {
    const getBrands = async () => {
      try {
        const res = await fetch(ApiUrl + "/api/user/get-brands", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await res.json();

        if (data.status === "success") {
          setBrands(data.list_of_brands);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.error("Error fetching dealerships:", error);
        // Handle error or show a toast if needed
      }
    };
    const fetchUsers = async () => {
      try {
        const res = await fetch(
          ApiUrl + "/api/user/representative",

          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("Get users res: ", res);
        const data = await res.json();
        // console.log("Get users data: ", data)
        // console.log(result)
        if (data.status === "success") {
          const rep =
            data?.representatives &&
            data?.representatives.length > 0 &&
            data?.representatives.map((user) => ({
              value: user.id,
              label: user.name,
            }));
          setRepOptions(rep);

          // Set state with new data
        } else {
          toast.error(data.message);
        }
      } catch (err) {
        toast.error("Something went wrong");
      } finally {
      }
    };

    getBrands();
    fetchUsers();
  }, [token]);

  const handleSubmit = async (values, { resetForm }, error) => {
    try {
      setLoading(true);
      const formdata = new FormData();
      Object.keys(values).forEach((key) => {
        formdata.append(key, values[key]);
      });
      const res = await fetch(ApiUrl + "/api/user/create-dealership", {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formdata,
      });
      const data = await res.json();
      if (data.status === "success") {
        resetForm();
        setSelected(null);
        setSelectedDealership(data.dealer_ship.id);
        button.click();
        toast.success(data.message);
        setDealerTrigger((prevState) => {
          return !prevState;
        });
        // setNewDealership({
        //   label: data.dealer_ship.name,
        //   value: data.dealer_ship.id,
        // });
      } else {
        toast.error("Something went wrong");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const brandOptions =
    brands &&
    brands.length > 0 &&
    brands.map((brand) => ({
      value: brand.id,
      label: brand.name,
    }));

  return (
    <div id="myModalCon" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <Formik
            initialValues={initialData}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            id="creationsuccess"
          >
            {({ errors, values, setValues, resetForm, setFieldValue }) => (
              <Form>
                <div
                  class="modal-header"
                  style={{
                    borderBottom: isDarkMode
                      ? "1px solid #495057"
                      : "1px solid #dee2e6",
                  }}
                >
                  <h3 style={{ color: "#000000" }}>
                    🗂 {translations["commonWords"].addDealership[language]}
                  </h3>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    onClick={() => button.click()}
                    style={{
                      filter: isDarkMode ? "invert(1)" : "none", // Invert the color in dark mode
                      color: isDarkMode
                        ? "#ffffff"
                        : "rgb(255, 255, 255) !important",
                    }}
                    id="closeModalBtn"
                  ></button>
                </div>
                <div
                  className="modal-body"
                  style={{ height: "300px", overflowY: "scroll" }}
                >
                  <div className="row">
                    <div className="col-6" style={{ paddingRight: "0px" }}>
                      <label for="inputadddealership" class="form-label">
                        {translations["commonWords"].dealership[language]}
                      </label>
                      <div class="mb-3">
                        <Field
                          name="name"
                          type="text"
                          className="form-control"
                          id="inputadddealership"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                          placeholder={
                            translations["commonFields"].name[language]
                          }
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <label for="inputadddealership" class="form-label">
                        {translations.quickCreationModal.country[language]}
                      </label>
                      <div className="mb-3">
                        {/* <Field
                          name="country"
                          render={({ field, form }) => (
                            <Select
                              className="selectpicker"
                              aria-labelledby="dealership-name-quick"
                              // isClearable
                              // options={packageOptions}
                              placeholder={
                                translations["dropdown"].select[language]
                              }
                              isSearchable
                              // value={
                              //   (packageOptions &&
                              //     packageOptions.length > 0 &&
                              //     packageOptions.find(
                              //       (option) => option.value === field.value
                              //     )) || {
                              //     value: "",
                              //     label:
                              //       translations["dropdown"].select[language],
                              //   }
                              // }
                              onChange={(selectedOption) =>
                                form.setFieldValue(
                                  field.name,
                                  selectedOption.value
                                )
                              }
                              maxMenuHeight={"120px"}
                              styles={{
                                input: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "white",
                                  border: state.isFocused
                                    ? "1px solid #80bdff"
                                    : isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6", // Adjust the border color for focus
                                  boxShadow: state.isFocused
                                    ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                    : "none", // Bootstrap's box shadow for focus
                                  borderRadius: ".45rem",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the indicator separator
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                  color: isDarkMode ? "#ffffff" : "#868686",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  margin: "0", // Remove default margin
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                  // Additional styles for placeholder
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: state.isHovered
                                    ? "#80bdff"
                                    : isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  ":hover": {
                                    backgroundColor: isDarkMode
                                      ? "#34DBB4"
                                      : "#34DBB4",
                                  },
                                }),
                              }}
                            />
                          )}
                        /> */}
                        <Field
                          name="country"
                          type="text"
                          className="form-control"
                          id="inputadddealership"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                          placeholder={
                            translations.quickCreationModal.country[language]
                          }
                        />
                        <ErrorMessage
                          name="country"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6" style={{ paddingRight: "0px" }}>
                      <label for="inputadddealership" class="form-label">
                        {translations.quickCreationModal.state[language]}
                      </label>
                      <div className="mb-3">
                        {/* <Field
                          name="state"
                          render={({ field, form }) => (
                            <Select
                              className="selectpicker"
                              aria-labelledby="dealership-name-quick"
                              // isClearable
                              // options={packageOptions}
                              placeholder={
                                translations["dropdown"].select[language]
                              }
                              isSearchable
                              // value={
                              //   (packageOptions &&
                              //     packageOptions.length > 0 &&
                              //     packageOptions.find(
                              //       (option) => option.value === field.value
                              //     )) || {
                              //     value: "",
                              //     label:
                              //       translations["dropdown"].select[language],
                              //   }
                              // }
                              onChange={(selectedOption) =>
                                form.setFieldValue(
                                  field.name,
                                  selectedOption.value
                                )
                              }
                              maxMenuHeight={"120px"}
                              styles={{
                                input: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "white",
                                  border: state.isFocused
                                    ? "1px solid #80bdff"
                                    : isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6", // Adjust the border color for focus
                                  boxShadow: state.isFocused
                                    ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                    : "none", // Bootstrap's box shadow for focus
                                  borderRadius: ".45rem",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the indicator separator
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                  color: isDarkMode ? "#ffffff" : "#868686",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  margin: "0", // Remove default margin
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                  // Additional styles for placeholder
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: state.isHovered
                                    ? "#80bdff"
                                    : isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  ":hover": {
                                    backgroundColor: isDarkMode
                                      ? "#34DBB4"
                                      : "#34DBB4",
                                  },
                                }),
                              }}
                            />
                          )}
                        /> */}
                        <Field
                          name="state"
                          type="text"
                          className="form-control"
                          id="inputadddealership"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                          placeholder={
                            translations.quickCreationModal.state[language]
                          }
                        />
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <label for="inputadddealership" class="form-label">
                        {translations.quickCreationModal.city[language]}
                      </label>
                      <div className="mb-3">
                        {/* <Field
                          name="city"
                          render={({ field, form }) => (
                            <Select
                              className="selectpicker"
                              aria-labelledby="dealership-name-quick"
                              // isClearable
                              // options={packageOptions}
                              placeholder={
                                translations["dropdown"].select[language]
                              }
                              isSearchable
                              // value={
                              //   (packageOptions &&
                              //     packageOptions.length > 0 &&
                              //     packageOptions.find(
                              //       (option) => option.value === field.value
                              //     )) || {
                              //     value: "",
                              //     label:
                              //       translations["dropdown"].select[language],
                              //   }
                              // }
                              onChange={(selectedOption) =>
                                form.setFieldValue(
                                  field.name,
                                  selectedOption.value
                                )
                              }
                              maxMenuHeight={"120px"}
                              styles={{
                                input: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "white",
                                  border: state.isFocused
                                    ? "1px solid #80bdff"
                                    : isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6", // Adjust the border color for focus
                                  boxShadow: state.isFocused
                                    ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                    : "none", // Bootstrap's box shadow for focus
                                  borderRadius: ".45rem",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the indicator separator
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                  color: isDarkMode ? "#ffffff" : "#868686",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  margin: "0", // Remove default margin
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                  // Additional styles for placeholder
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: state.isHovered
                                    ? "#80bdff"
                                    : isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  ":hover": {
                                    backgroundColor: isDarkMode
                                      ? "#34DBB4"
                                      : "#34DBB4",
                                  },
                                }),
                              }}
                            />
                          )}
                        /> */}
                        <Field
                          name="city"
                          type="text"
                          className="form-control"
                          id="inputadddealership"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                          placeholder={
                            translations.quickCreationModal.city[language]
                          }
                        />
                        <ErrorMessage
                          name="city"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6" style={{ paddingRight: "0px" }}>
                      <label for="inputadddealership" class="form-label">
                        {translations.quickCreationModal.address[language]}
                      </label>
                      <div className="mb-3">
                        {/* <Field
                          name="city"
                          render={({ field, form }) => (
                            <Select
                              className="selectpicker"
                              aria-labelledby="dealership-name-quick"
                              // isClearable
                              // options={packageOptions}
                              placeholder={
                                translations["dropdown"].select[language]
                              }
                              isSearchable
                              // value={
                              //   (packageOptions &&
                              //     packageOptions.length > 0 &&
                              //     packageOptions.find(
                              //       (option) => option.value === field.value
                              //     )) || {
                              //     value: "",
                              //     label:
                              //       translations["dropdown"].select[language],
                              //   }
                              // }
                              onChange={(selectedOption) =>
                                form.setFieldValue(
                                  field.name,
                                  selectedOption.value
                                )
                              }
                              maxMenuHeight={"120px"}
                              styles={{
                                input: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "white",
                                  border: state.isFocused
                                    ? "1px solid #80bdff"
                                    : isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6", // Adjust the border color for focus
                                  boxShadow: state.isFocused
                                    ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                    : "none", // Bootstrap's box shadow for focus
                                  borderRadius: ".45rem",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the indicator separator
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                  color: isDarkMode ? "#ffffff" : "#868686",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  margin: "0", // Remove default margin
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                  // Additional styles for placeholder
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: state.isHovered
                                    ? "#80bdff"
                                    : isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  ":hover": {
                                    backgroundColor: isDarkMode
                                      ? "#34DBB4"
                                      : "#34DBB4",
                                  },
                                }),
                              }}
                            />
                          )}
                        /> */}
                        <Field
                          name="address"
                          type="text"
                          className="form-control"
                          id="inputadddealership"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                          placeholder={
                            translations.quickCreationModal.address[language]
                          }
                        />
                        <ErrorMessage
                          name="address"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <label for="inputadddealership" class="form-label">
                        {
                          translations.forms.formFields.conquestSection
                            .postalCode[language]
                        }
                      </label>
                      <div className="mb-3">
                        {/* <Field
                          name="state"
                          render={({ field, form }) => (
                            <Select
                              className="selectpicker"
                              aria-labelledby="dealership-name-quick"
                              // isClearable
                              // options={packageOptions}
                              placeholder={
                                translations["dropdown"].select[language]
                              }
                              isSearchable
                              // value={
                              //   (packageOptions &&
                              //     packageOptions.length > 0 &&
                              //     packageOptions.find(
                              //       (option) => option.value === field.value
                              //     )) || {
                              //     value: "",
                              //     label:
                              //       translations["dropdown"].select[language],
                              //   }
                              // }
                              onChange={(selectedOption) =>
                                form.setFieldValue(
                                  field.name,
                                  selectedOption.value
                                )
                              }
                              maxMenuHeight={"120px"}
                              styles={{
                                input: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "white",
                                  border: state.isFocused
                                    ? "1px solid #80bdff"
                                    : isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6", // Adjust the border color for focus
                                  boxShadow: state.isFocused
                                    ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                    : "none", // Bootstrap's box shadow for focus
                                  borderRadius: ".45rem",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the indicator separator
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                  color: isDarkMode ? "#ffffff" : "#868686",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  margin: "0", // Remove default margin
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                  // Additional styles for placeholder
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: state.isHovered
                                    ? "#80bdff"
                                    : isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  ":hover": {
                                    backgroundColor: isDarkMode
                                      ? "#34DBB4"
                                      : "#34DBB4",
                                  },
                                }),
                              }}
                            />
                          )}
                        /> */}
                        <Field
                          name="postal_code"
                          type="text"
                          className="form-control"
                          id="inputadddealership"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                          placeholder={
                            translations.forms.formFields.conquestSection
                              .postalCode[language]
                          }
                        />
                        <ErrorMessage
                          name="postal_code"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6" style={{ paddingRight: "0px" }}>
                      <label for="inputadddealership" class="form-label">
                        {translations.quickCreationModal.timezone[language]}
                      </label>
                      <div className="mb-3">
                        {/* <Field
                          name="city"
                          render={({ field, form }) => (
                            <Select
                              className="selectpicker"
                              aria-labelledby="dealership-name-quick"
                              // isClearable
                              // options={packageOptions}
                              placeholder={
                                translations["dropdown"].select[language]
                              }
                              isSearchable
                              // value={
                              //   (packageOptions &&
                              //     packageOptions.length > 0 &&
                              //     packageOptions.find(
                              //       (option) => option.value === field.value
                              //     )) || {
                              //     value: "",
                              //     label:
                              //       translations["dropdown"].select[language],
                              //   }
                              // }
                              onChange={(selectedOption) =>
                                form.setFieldValue(
                                  field.name,
                                  selectedOption.value
                                )
                              }
                              maxMenuHeight={"120px"}
                              styles={{
                                input: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "white",
                                  border: state.isFocused
                                    ? "1px solid #80bdff"
                                    : isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6", // Adjust the border color for focus
                                  boxShadow: state.isFocused
                                    ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                    : "none", // Bootstrap's box shadow for focus
                                  borderRadius: ".45rem",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the indicator separator
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                  color: isDarkMode ? "#ffffff" : "#868686",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  margin: "0", // Remove default margin
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                  // Additional styles for placeholder
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: state.isHovered
                                    ? "#80bdff"
                                    : isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  ":hover": {
                                    backgroundColor: isDarkMode
                                      ? "#34DBB4"
                                      : "#34DBB4",
                                  },
                                }),
                              }}
                            />
                          )}
                        /> */}
                        <Field
                          name="timezone"
                          type="text"
                          className="form-control"
                          id="inputadddealership"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                          placeholder={
                            translations.quickCreationModal.timezone[language]
                          }
                        />
                        <ErrorMessage
                          name="timezone"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <label for="inputadddealership" class="form-label">
                        {translations.quickCreationModal.phoneNumber[language]}
                      </label>
                      <div className="mb-3">
                        {/* <Field
                          name="state"
                          render={({ field, form }) => (
                            <Select
                              className="selectpicker"
                              aria-labelledby="dealership-name-quick"
                              // isClearable
                              // options={packageOptions}
                              placeholder={
                                translations["dropdown"].select[language]
                              }
                              isSearchable
                              // value={
                              //   (packageOptions &&
                              //     packageOptions.length > 0 &&
                              //     packageOptions.find(
                              //       (option) => option.value === field.value
                              //     )) || {
                              //     value: "",
                              //     label:
                              //       translations["dropdown"].select[language],
                              //   }
                              // }
                              onChange={(selectedOption) =>
                                form.setFieldValue(
                                  field.name,
                                  selectedOption.value
                                )
                              }
                              maxMenuHeight={"120px"}
                              styles={{
                                input: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "white",
                                  border: state.isFocused
                                    ? "1px solid #80bdff"
                                    : isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6", // Adjust the border color for focus
                                  boxShadow: state.isFocused
                                    ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                    : "none", // Bootstrap's box shadow for focus
                                  borderRadius: ".45rem",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the indicator separator
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                  color: isDarkMode ? "#ffffff" : "#868686",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  margin: "0", // Remove default margin
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                  // Additional styles for placeholder
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: state.isHovered
                                    ? "#80bdff"
                                    : isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  ":hover": {
                                    backgroundColor: isDarkMode
                                      ? "#34DBB4"
                                      : "#34DBB4",
                                  },
                                }),
                              }}
                            />
                          )}
                        /> */}
                        <Field
                          name="phone_number"
                          type="text"
                          className="form-control"
                          id="inputadddealership"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                          placeholder={
                            translations.quickCreationModal.phoneNumber[
                              language
                            ]
                          }
                        />
                        <ErrorMessage
                          name="phone_number"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6" style={{ paddingRight: "0px" }}>
                      <label class="form-label">
                        {" "}
                        {translations.quickCreationModal.brand[language]}
                      </label>
                      <div className="mb-3">
                        <div class="">
                          <div>
                            <Select
                              name="brand_id"
                              className="selectpicker"
                              maxMenuHeight={"85px"}
                              value={selected}
                              onChange={(selectedOption) => {
                                setSelected({
                                  value: selectedOption.value,
                                  label: selectedOption.label,
                                });
                                setValues({
                                  ...values,
                                  brand_id: selectedOption.value,
                                });
                              }}
                              aria-labelledby="dealership-name-quick"
                              options={brandOptions}
                              placeholder={
                                translations["dropdown"].select[language]
                              }
                              isSearchable
                              styles={{
                                input: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "white",
                                  border: state.isFocused
                                    ? "1px solid #80bdff"
                                    : isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6", // Adjust the border color for focus
                                  boxShadow: state.isFocused
                                    ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                    : "none", // Bootstrap's box shadow for focus
                                  borderRadius: ".45rem",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the indicator separator
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                  color: isDarkMode ? "#ffffff" : "#868686",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  margin: "0", // Remove default margin
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                  // Additional styles for placeholder
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: state.isHovered
                                    ? "#80bdff"
                                    : isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  ":hover": {
                                    backgroundColor: isDarkMode
                                      ? "#48cae4"
                                      : "#48cae4",
                                  },
                                }),
                              }}
                            />
                            <ErrorMessage
                              name="brand_id"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <label for="inputadddealership" class="form-label">
                        {translations.quickCreationModal.group[language]}
                      </label>
                      <div className="mb-3">
                        {/* <Field
                          name="state"
                          render={({ field, form }) => (
                            <Select
                              className="selectpicker"
                              aria-labelledby="dealership-name-quick"
                              // isClearable
                              // options={packageOptions}
                              placeholder={
                                translations["dropdown"].select[language]
                              }
                              isSearchable
                              // value={
                              //   (packageOptions &&
                              //     packageOptions.length > 0 &&
                              //     packageOptions.find(
                              //       (option) => option.value === field.value
                              //     )) || {
                              //     value: "",
                              //     label:
                              //       translations["dropdown"].select[language],
                              //   }
                              // }
                              onChange={(selectedOption) =>
                                form.setFieldValue(
                                  field.name,
                                  selectedOption.value
                                )
                              }
                              maxMenuHeight={"120px"}
                              styles={{
                                input: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "white",
                                  border: state.isFocused
                                    ? "1px solid #80bdff"
                                    : isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6", // Adjust the border color for focus
                                  boxShadow: state.isFocused
                                    ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                    : "none", // Bootstrap's box shadow for focus
                                  borderRadius: ".45rem",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the indicator separator
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                  color: isDarkMode ? "#ffffff" : "#868686",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  margin: "0", // Remove default margin
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                  // Additional styles for placeholder
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: state.isHovered
                                    ? "#80bdff"
                                    : isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  ":hover": {
                                    backgroundColor: isDarkMode
                                      ? "#34DBB4"
                                      : "#34DBB4",
                                  },
                                }),
                              }}
                            />
                          )}
                        /> */}
                        <Field
                          name="group_id"
                          type="number"
                          className="form-control"
                          id="inputadddealership"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                          placeholder={
                            translations.quickCreationModal.group[language]
                          }
                        />
                        <ErrorMessage
                          name="group_id"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6" style={{ paddingRight: "0px" }}>
                      <label class="form-label">
                        {" "}
                        {translations.forms.formFields.representative[language]}
                      </label>
                      <div className="mb-3">
                        <Field
                          name="representative_id"
                          render={({ field, form }) => (
                            <Select
                              className="selectpicker"
                              aria-labelledby="dealership-name-quick"
                              // isClearable
                              options={repOptions}
                              placeholder={
                                translations["dropdown"].select[language]
                              }
                              isSearchable
                              value={
                                (repOptions &&
                                  repOptions.length > 0 &&
                                  repOptions.find(
                                    (option) => option.value === field.value
                                  )) || {
                                  value: "",
                                  label:
                                    translations["dropdown"].select[language],
                                }
                              }
                              onChange={(selectedOption) =>
                                form.setFieldValue(
                                  field.name,
                                  selectedOption.value
                                )
                              }
                              maxMenuHeight={"120px"}
                              styles={{
                                input: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "white",
                                  border: state.isFocused
                                    ? "1px solid #80bdff"
                                    : isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6", // Adjust the border color for focus
                                  boxShadow: state.isFocused
                                    ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                    : "none", // Bootstrap's box shadow for focus
                                  borderRadius: ".45rem",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the indicator separator
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                  color: isDarkMode ? "#ffffff" : "#868686",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  margin: "0", // Remove default margin
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                  // Additional styles for placeholder
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: state.isHovered
                                    ? "#80bdff"
                                    : isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  ":hover": {
                                    backgroundColor: isDarkMode
                                      ? "#48cae4"
                                      : "#48cae4",
                                  },
                                }),
                              }}
                            />
                          )}
                        />
                        <ErrorMessage
                          name="representative_id"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <label for="inputadddealership" class="form-label">
                        {
                          translations.quickCreationModal.primaryContact[
                            language
                          ]
                        }
                      </label>
                      <div className="mb-3">
                        {/* <Field
                          name="state"
                          render={({ field, form }) => (
                            <Select
                              className="selectpicker"
                              aria-labelledby="dealership-name-quick"
                              // isClearable
                              // options={packageOptions}
                              placeholder={
                                translations["dropdown"].select[language]
                              }
                              isSearchable
                              // value={
                              //   (packageOptions &&
                              //     packageOptions.length > 0 &&
                              //     packageOptions.find(
                              //       (option) => option.value === field.value
                              //     )) || {
                              //     value: "",
                              //     label:
                              //       translations["dropdown"].select[language],
                              //   }
                              // }
                              onChange={(selectedOption) =>
                                form.setFieldValue(
                                  field.name,
                                  selectedOption.value
                                )
                              }
                              maxMenuHeight={"120px"}
                              styles={{
                                input: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "white",
                                  border: state.isFocused
                                    ? "1px solid #80bdff"
                                    : isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6", // Adjust the border color for focus
                                  boxShadow: state.isFocused
                                    ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                    : "none", // Bootstrap's box shadow for focus
                                  borderRadius: ".45rem",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the indicator separator
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                  color: isDarkMode ? "#ffffff" : "#868686",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  margin: "0", // Remove default margin
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                  // Additional styles for placeholder
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: state.isHovered
                                    ? "#80bdff"
                                    : isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  ":hover": {
                                    backgroundColor: isDarkMode
                                      ? "#34DBB4"
                                      : "#34DBB4",
                                  },
                                }),
                              }}
                            />
                          )}
                        /> */}
                        <Field
                          name="primary_contact"
                          type="text"
                          className="form-control"
                          id="inputadddealership"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                          placeholder={
                            translations.quickCreationModal.primaryContact[
                              language
                            ]
                          }
                        />
                        <ErrorMessage
                          name="primary_contact"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <label for="inputadddealership" class="form-label">
                        {translations.quickCreationModal.crm[language]}
                      </label>
                      <div className="mb-3">
                        {/* <Field
                          name="state"
                          render={({ field, form }) => (
                            <Select
                              className="selectpicker"
                              aria-labelledby="dealership-name-quick"
                              // isClearable
                              // options={packageOptions}
                              placeholder={
                                translations["dropdown"].select[language]
                              }
                              isSearchable
                              // value={
                              //   (packageOptions &&
                              //     packageOptions.length > 0 &&
                              //     packageOptions.find(
                              //       (option) => option.value === field.value
                              //     )) || {
                              //     value: "",
                              //     label:
                              //       translations["dropdown"].select[language],
                              //   }
                              // }
                              onChange={(selectedOption) =>
                                form.setFieldValue(
                                  field.name,
                                  selectedOption.value
                                )
                              }
                              maxMenuHeight={"120px"}
                              styles={{
                                input: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "white",
                                  border: state.isFocused
                                    ? "1px solid #80bdff"
                                    : isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6", // Adjust the border color for focus
                                  boxShadow: state.isFocused
                                    ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                    : "none", // Bootstrap's box shadow for focus
                                  borderRadius: ".45rem",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the indicator separator
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                  color: isDarkMode ? "#ffffff" : "#868686",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  margin: "0", // Remove default margin
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                  // Additional styles for placeholder
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: state.isHovered
                                    ? "#80bdff"
                                    : isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  ":hover": {
                                    backgroundColor: isDarkMode
                                      ? "#34DBB4"
                                      : "#34DBB4",
                                  },
                                }),
                              }}
                            />
                          )}
                        /> */}
                        <Field
                          name="crm_link"
                          type="text"
                          className="form-control"
                          id="inputadddealership"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                          placeholder={
                            translations.quickCreationModal.crm[language]
                          }
                        />
                        <ErrorMessage
                          name="crm_link"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <Dropzone
                        // disabled={!editable}
                        style={{
                          backgroundColor: "#ced4da66",
                        }}
                        multiple={false}
                        accept=".png, .jpeg, .jpg, .webp"
                        onDrop={(acceptedFiles) => {
                          // console.log(acceptedFiles)
                          if (acceptedFiles.length > 0) {
                            setValues({ ...values, logo: acceptedFiles[0] });
                          }
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="client-form-row w-100">
                            <div className="w-100">
                              <label
                                htmlFor="logo"
                                className="pb-3"
                                style={{
                                  color: isDarkMode ? "#33dbb4" : "",
                                  fontSize: "20px",
                                  fontWeight: 600,
                                }} /*class="form-label"*/
                              >
                                {translations.quickCreationModal.logo[language]}
                              </label>

                              <div
                                {...getRootProps()}
                                role="presentation"
                                tabIndex="0"
                                style={{
                                  background: "rgb(231, 236, 239)",
                                  // backgroundImage: `url(${URL.createObjectURL(values.logo)})`,
                                  // backgroundSize: "cover",
                                  // backgroundPosition: "center",
                                  padding: "25px 0px",
                                  borderRadius: "0.375rem",
                                  height: "180px",
                                  display: "flex",
                                  alignContent: "center",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <input
                                  {...getInputProps()}
                                  type="file"
                                  accept=".png, .jpeg, .jpg, .webp"
                                />
                                <div className="client-file-input">
                                  <p
                                    style={{
                                      textAlign: "center",
                                      fontSize: "15px",
                                      fontWeight: 700,
                                      marginBottom: "30px",
                                      marginTop: "20px",
                                    }}
                                  >
                                    {
                                      translations.quickCreationModal.logo2[
                                        language
                                      ]
                                    }
                                  </p>
                                  <div className="pb-5">
                                    <i
                                      className="bi bi-file-earmark-arrow-up-fill"
                                      style={{
                                        border: "10px dashed #babec1",
                                        padding: "15px 100px",
                                        color: "#babec1",
                                        borderRadius: "0.375rem",
                                        fontSize: "40pt",
                                        marginTop: "10px",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </div>
                              {values.logo && (
                                <div>
                                  <div className="d-flex gap-3 align-items-center ">
                                    <div
                                      className="d-flex gap-1 py-3 align-items-center"
                                      style={{ position: "relative" }}
                                    >
                                      <i
                                        className="bi bi-x-circle"
                                        onClick={() =>
                                          setValues({ ...values, logo: "" })
                                        }
                                        style={{
                                          position: "absolute",
                                          top: "16px",
                                          right: "4px",
                                          color: "red",
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                      ></i>
                                      <img
                                        className=""
                                        width={"65px"}
                                        height={"60px"}
                                        src={URL.createObjectURL(values.logo)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <ErrorMessage
                                name="logo"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </div>
                <div
                  class="modal-footer"
                  id="modalfooter"
                  style={{
                    borderTop: isDarkMode
                      ? "1px solid #495057"
                      : "1px solid #dee2e6",
                  }}
                >
                  <button
                    type="submit"
                    class="admin-btn btn btn-primary"
                    id="add-dealership"

                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                  >
                    {loading
                      ? translations["commonFields"].addingBtn[language]
                      : translations["commonFields"].addBtn[language]}
                  </button>
                  <button
                    id="closebtn3"
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    // data-bs-toggle={quickAdd ? "modal" : ""}
                    // data-bs-target={quickAdd ? "#modalQuickAdd" : ""}
                    onClick={() => {
                      resetForm();
                      setValues({
                        name: "",
                        country: "",
                        state: "",
                        city: "",
                        address: "",
                        postal_code: "",
                        timezone: "",
                        phone_number: "",
                        primary_contact: "",
                        group_id: "",
                        representative_id: "",
                        brand_id: "",
                        logo: "",
                        crm_link: "",
                      });
                      setSelected("");
                    }}
                  >
                    {translations["commonFields"].closeBtn[language]}
                  </button>
                  {/* <br />
                  <div
                    class="alert alert-danger d-flex align-items-center invisible"
                    id="clientFormAlert"
                    role="alert"
                  >
                    <div id="clientFormAlertText"></div>
                  </div> */}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default AddDealershipModal;
