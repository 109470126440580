import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";

function AddWeekModal({ handleCopy, week, eventId, setWeek, setEventId, isDarkMode }) {
  const { language } = useLanguage();
  const [valid, setValid] = useState(false);
  var button = document.getElementById("closebtn2");

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (week === "") {
      setValid(true);
      return;
    } else {
      handleCopy(eventId, week);
      button.click();
    }
  };
  return (
    <div id="copyEvent" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <form onSubmit={handleSubmit}>
            <div class="modal-header" style={{
                  
                  borderBottom: isDarkMode ? "1px solid #495057" : "1px solid #dee2e6"
                  
                }}>
              <h3>{translations.addWeek.title[language]}</h3>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                id="closeModalBtnn"
                style={{
                  filter: isDarkMode ? "invert(1)" : "none", // Invert the color in dark mode
                  color: isDarkMode
                    ? "#ffffff"
                    : "rgb(255, 255, 255) !important",
                }}
              ></button>
            </div>
            <div className="modal-body">
              <label for="event-1-Week-quick" class="form-label">
                {" "}
                {translations.quickCreationModal.week[language]}
              </label>
              <input
                type="number"
                value={week}
                className="form-control"
                id="event-1-Week-quick"
                onChange={(e) => {
                  setWeek(e.target.value);
                  setValid(false);
                }}
                style={{
                  backgroundColor: isDarkMode ? "transparent" : "",
                  color: isDarkMode ? "#ffffff" : "#000000",
                  border: isDarkMode ? "1px solid #495057" : "1px solid #dee2e6"
                  
                }}
              />
              {valid && week === "" && (
                <p className="text-danger ">
                  {
                    translations.quickCreationModal.validationsText.weekText[
                      language
                    ]
                  }
                </p>
              )}
            </div>
            <div
              style={{ height: "71px",borderTop: isDarkMode ? "1px solid #495057" : "1px solid #dee2e6" }}
              class="modal-footer"
              id="modalfooter"
              
            >
              <button
                type="submit"
                class="btn admin-btn btn-primary"
                id="form-send-client"
              >
                
                {translations.commonWords.sendBtn[language]}
              </button>
              <button
                id="closebtn2"
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  setWeek("");
                  setEventId("");
                }}
              >
                {translations.commonFields.closeBtn[language]}
              </button>
              <div class="row" style={{ width: "100%" }}>
                <div
                  class="alert alert-danger d-flex align-items-center invisible"
                  id="clientFormAlert"
                  role="alert"
                >
                  <svg
                    class="bi flex-shrink-0 me-2"
                    width="24"
                    height="24"
                    role="img"
                    aria-label="Danger:"
                  >
                    {/*<use xlink:href="#exclamation-triangle-fill"></use>*/}
                  </svg>
                  <div id="clientFormAlertText"></div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddWeekModal;
