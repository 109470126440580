import { useEffect, useState } from "react";
import AddDealershipModal from "./AddDealershipModal";
import { toast } from "react-toastify";
import useDealerships from "./useDealerships";
import { ApiUrl } from "./ApiUrl";
import Select from "react-select";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";
import Loader from "./loader";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { getISOWeek } from "date-fns";

const ModalAdminQuickAdd = ({
  setEventTrigger,
  dealerships,
  setDealerTrigger,
  setDisplayComponent,
  isDarkMode,
}) => {
  const [newdealership, setNewDealership] = useState();
  const { language } = useLanguage();
  const pageTranslations = translations["quickCreationModal"];
  const [loading, setLoading] = useState(false);
  const [dealershipData, setDealershipData] = useState(null);
  const [events, setEvents] = useState([]);
  const token = localStorage.getItem("token");
  var button = document.getElementById("closeBtn0");

  const validationSchema = Yup.object().shape({
    dealership_id: Yup.string().required(
      pageTranslations.validationsText.dealershipText[language]
    ),
    start_date: Yup.date().required(
      translations.forms.validationTranslations.start_date[language]
    ),
    end_date: Yup.date().required(
      translations.forms.validationTranslations.end_date[language]
    ),
    week: Yup.string().required(
      pageTranslations.validationsText.weekText[language]
    ), //.required('Dealership Name is required'),
  });

  const handleSubmit = async (values, { resetForm }, error) => {
    try {
      setLoading(true);
      const res = await fetch(ApiUrl + "/api/user/private-events", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
      });
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data.message);
        window.location.reload();
        setNewDealership(null);
        resetForm();
        button.click();
        // setEventTrigger(data.event_list);
        // setDisplayComponent("edit-form");

        // window.history.pushState(
        //   {
        //     id: "gallery",
        //     randomData: window.Math.random(),
        //   },
        //   "title",
        //   "/event-portal?page=edit-form&parent_id=" + data.event_url
        // );
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };

  useEffect(() => {
    const getEvents = async () => {
      try {
        const res = await fetch(ApiUrl + "/api/user/events", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await res.json();

        if (data.status === "success") {
          const eventOptions =
            data.private_events &&
            data.private_events.length > 0 &&
            data.private_events.map((event) => ({
              value: event.id,
              label: event.nom_vp,
            }));
          setEvents(eventOptions);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.error("Error fetching events :", error);
        // Handle error or show a toast if needed
      }
    };
    getEvents();
  }, []);

  const options =
    dealerships &&
    dealerships.map((dealership) => ({
      value: dealership.id,
      label: dealership.name,
    }));

  return (
    <>
      <div
        id="modalQuickAdd"
        className={`modal fade`}
        tabIndex="-1"
        aria-bs-modal="true"
        role="dialog"
        aria-bs-hidden="true"
      >
        {/* </div>/*<div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-bs-labelledby="exampleModalLabel" aria-bs-hidden="true">>*/}
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <Formik
              initialValues={{
                dealership_id: newdealership ? newdealership.value : "",
                start_date: "",
                end_date: "",
                week: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              id="modalQuickAddBody"
              enableReinitialize={true}
            >
              {({ errors, values, setValues, resetForm }) => (
                <Form>
                  <div
                    className="modal-header"
                    style={{
                      borderBottom: isDarkMode
                        ? "1px solid #495057"
                        : "1px solid #dee2e6",
                    }}
                  >
                    <h3 style={{ color: "#000000" }}>
                      {" "}
                      {pageTranslations.title[language]}
                    </h3>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      id="close-ModalBtn"
                      onClick={() => setNewDealership(null)}
                      style={{
                        filter: isDarkMode ? "invert(1)" : "none", // Invert the color in dark mode
                        color: isDarkMode
                          ? "#ffffff"
                          : "rgb(255, 255, 255) !important",
                      }}
                    ></button>
                  </div>

                  <div className="modal-body">
                    <div className="row d-flex align-items-center ">
                      <label className="form-label">
                        {" "}
                        {pageTranslations.dealership[language]}
                      </label>
                      <div className="col-9">
                        <div>
                          <Select
                            name="dealership_id"
                            className="selectpicker"
                            maxMenuHeight={"150px"}
                            onChange={(selectedOption) => {
                              setNewDealership({
                                value: selectedOption.value,
                                label: selectedOption.label,
                              });

                              setValues({
                                ...values,
                                dealer: selectedOption.value,
                              });
                            }}
                            value={newdealership}
                            aria-labelledby="dealership-name-quick"
                            // isClearable
                            options={options}
                            placeholder={
                              translations["dropdown"].select[language]
                            }
                            isSearchable
                            styles={{
                              input: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                              }),
                              control: (provided, state) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black",
                                backgroundColor: isDarkMode
                                  ? "transparent"
                                  : "white",
                                border: state.isFocused
                                  ? "1px solid #80bdff"
                                  : isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6", // Adjust the border color for focus
                                boxShadow: state.isFocused
                                  ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                  : "none", // Bootstrap's box shadow for focus
                                borderRadius: ".45rem",
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                // color: isDarkMode ? "#ffffff" : "black",
                                // backgroundColor: isDarkMode ? "transparent" : "white",
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none", // Hide the indicator separator
                              }),
                              dropdownIndicator: (provided) => ({
                                ...provided,
                                padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                color: isDarkMode ? "#ffffff" : "#868686",
                                // backgroundColor: isDarkMode ? "transparent" : "white",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black",
                                backgroundColor: isDarkMode
                                  ? "#1f2023"
                                  : "white",
                                margin: "0", // Remove default margin
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                // Additional styles for placeholder
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                color: isDarkMode ? "#ffffff" : "black",
                                backgroundColor: state.isHovered
                                  ? "#80bdff"
                                  : isDarkMode
                                  ? "#1f2023"
                                  : "white",
                                ":hover": {
                                  backgroundColor: isDarkMode
                                    ? "#34DBB4"
                                    : "#34DBB4",
                                },
                              }),
                            }}
                          />
                          {/* <Field
                            name="event_id"
                            render={({ field, form }) => (
                              <Select
                                className="selectpicker"
                                aria-labelledby="dealership-name-quick"
                                // isClearable
                                options={events}
                                placeholder={
                                  translations["dropdown"].select[language]
                                }
                                isSearchable
                                value={
                                  (events &&
                                    events.length > 0 &&
                                    events.find(
                                      (option) => option.value === field.value
                                    )) || {
                                    value: "",
                                    label:
                                      translations["dropdown"].select[language],
                                  }
                                }
                                onChange={(selectedOption) =>
                                  form.setFieldValue(
                                    field.name,
                                    selectedOption.value
                                  )
                                }
                                maxMenuHeight={"120px"}
                                styles={{
                                  input: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                  }),
                                  control: (provided, state) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "white",
                                    border: state.isFocused
                                      ? "1px solid #80bdff"
                                      : isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6", // Adjust the border color for focus
                                    boxShadow: state.isFocused
                                      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                      : "none", // Bootstrap's box shadow for focus
                                    borderRadius: ".25rem",
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    padding: "0.375rem 0.75rem", // Bootstrap's padding for the value container
                                    // color: isDarkMode ? "#ffffff" : "black",
                                    // backgroundColor: isDarkMode ? "transparent" : "white",
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none", // Hide the indicator separator
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                    color: isDarkMode ? "#ffffff" : "#868686",
                                    // backgroundColor: isDarkMode ? "transparent" : "white",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    margin: "0", // Remove default margin
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                    // Additional styles for placeholder
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: state.isHovered
                                      ? "#80bdff"
                                      : isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    ":hover": {
                                      backgroundColor: isDarkMode
                                        ? "#34DBB4"
                                        : "#34DBB4",
                                    },
                                  }),
                                }}
                              />
                            )}
                          /> */}
                          <ErrorMessage
                            name="dealership_id"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div>
                          <button
                            type="button"
                            className="client-btn"
                            id="add-dlr-trigger-quick"
                            data-bs-toggle="modal"
                            data-bs-target="#myModalCon"
                          >
                            <i className="bi bi-plus-circle"></i>{" "}
                            {translations.commonFields.addBtn[language]}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col">
                        <div class="row">
                          <div class="col">
                            <div class="mb-3">
                              <label for="last-sale-date" class="form-label">
                                {
                                  translations.forms.privateEventformFields
                                    .startDate[language]
                                }
                              </label>
                              <Field
                                type="date"
                                style={{
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                                name="start_date"
                                class={`form-control ${
                                  isDarkMode ? "custom-date" : ""
                                }`}
                                onChange={(e) => {
                                  const date = new Date(e.target.value);

                                  setValues({
                                    ...values,
                                    start_date: e.target.value,
                                    week: getISOWeek(date),
                                  });
                                }}
                                id="last-sale-date"
                              />
                              <ErrorMessage
                                name="start_date"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="last-sale-date" class="form-label">
                            {
                              translations.forms.privateEventformFields.endDate[
                                language
                              ]
                            }
                          </label>
                          <Field
                            type="date"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            name="end_date"
                            class={`form-control ${
                              isDarkMode ? "custom-date" : ""
                            }`}
                            id="last-sale-date"
                          />
                          <ErrorMessage
                            name="end_date"
                            component="div"
                            className="error text-danger"
                          />
                        </div>{" "}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3 mt-2">
                          <label
                            for="event-1-Week-quick"
                            className="form-label"
                          >
                            {" "}
                            {pageTranslations.week[language]}
                          </label>
                          <Field
                            name="week"
                            type="number"
                            disabled
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode
                                ? "#ced4da66"
                                : "#ced4da66",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            id="event-1-Week-quick"
                          />
                          <ErrorMessage
                            name="week"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal-footer"
                    style={{
                      borderTop: isDarkMode
                        ? "1px solid #495057"
                        : "1px solid #dee2e6",
                    }} /* justify-content-between"*/
                  >
                    {/* <div>Want to create a new user? <a className="text-primary" href="/create-user">Create a user</a></div> */}
                    {/* <div className="d-flex gap-2"> */}
                    <button
                      type="submit"
                      className="client-btn"
                      id="add-customer-quick"
                    >
                      <i className="bi bi-save"></i>{" "}
                      {loading
                        ? translations.commonFields.submitingBtn[language]
                        : pageTranslations.submitBtn[language]}
                    </button>
                    <button
                      id="closeBtn0"
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        resetForm();
                        setNewDealership(null);
                      }}
                    >
                      {" "}
                      {pageTranslations.closeBtn[language]}
                    </button>
                    {/* </div> */}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        {loading && <Loader />}
      </div>

      <AddDealershipModal
        setDealerTrigger={setDealerTrigger}
        setIsLoading={setLoading}
        isDarkMode={isDarkMode}
        setNewDealership={setNewDealership}
        dealerships={dealerships}
        quickAdd= {true}
      />
    </>
  );
};

export default ModalAdminQuickAdd;
