import React from "react";
import ReactPaginate from "react-paginate";
export default function PaginatedItems({
  total,
  pageNo,
  setPageNo,
  itemsPerPage=10,
  handlePageChange = () => { },
  isDarkMode
  
}) {
  // const itemsPerPage = 10;
  const pageCount = Math.ceil(total / itemsPerPage);
  const handlePageClick = (event) => {
    handlePageChange(event.selected + 1);
    setPageNo(event.selected + 1);
    // const newOffset = ((event.selected + 1) * itemsPerPage) % total;
  };
  return (
    <div style={{ width: '100%', display: "flex", justifyContent: "center", padding: "12px 0 10px 0"}}>
      <ReactPaginate
      
        className={"pagination"}
        previousClassName={isDarkMode ? "page-item2 first-page2 "  :"page-item first-page"}
        previousLinkClassName={`pagination-link ${pageNo === 1 ? "disabled" : ""
          }`}
        nextClassName={isDarkMode ? "page-item2 last-page2 "  :"page-item last-page"}
        nextLinkClassName={`pagination-link ${pageNo === pageCount ? "disabled" : ""
          }`}
        pageClassName={ "page-item "}
        pageLinkClassName={"pagination-link"}
        activeLinkClassName={isDarkMode ? "active-page2" : "active-page"}
        breakLabel="..."
        nextLabel={<i class="bi bi-chevron-right" ></i>}
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel={<i class="bi bi-chevron-left"></i>}
        renderOnZeroPageCount={null}
        forcePage={pageNo - 1}
      />
    </div>
  );
}