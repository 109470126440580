import React from "react";
import { useLanguage } from "../languageContext";
import translations from "../translation.json";
import numeral from "numeral";
const SalesOverview = ({graphData}) => {
  const { language } = useLanguage();
  return (
    <div className="d-flex gap-2">
      <div
        className="d-flex flex-column align-items-center justify-content-center rounded-1"
        style={{
          backgroundColor: "#34DBB4",
          minHeight: "250px",
          width: "34%",
        }}
      >
        <p className="text-white fw-bold">
          {" "}
          Avg {translations.forms.formFields.amount[language]} total{" "}
        </p>
        <p className="text-white fw-bolder" style={{ fontSize: "36px" }}>
          CAD {numeral (graphData?.aveAmount).format("0,000.00")}
        </p>
      </div>
      <div
        className="d-flex flex-column align-items-center justify-content-center rounded-1"
        style={{
          backgroundColor: "#48cae4",
          minHeight: "250px",
          width: "34%",
        }}
      >
        <p className="text-white fw-bold">
          {" "}
          Total {translations.forms.formFields.amount[language]} total{" "}
        </p>
        <p className="text-white fw-bolder" style={{ fontSize: "36px" }}>
          CAD {numeral (graphData?.totalAmount).format("0,000.00")}
        </p>
      </div>
      <div
        className=" d-flex flex-column align-items-center justify-content-center rounded-1"
        style={{
          boxShadow: "0 0 40px rgba(0, 0, 0, 0.15)",
          minHeight: "250px",
          width: "34%",
        }}
      >
        <p className="fw-bold"> Max. ({graphData?.vendorWithMaxAmount?.name})</p>
        <p className="fw-bolder" style={{ fontSize: "36px" }}>
          CAD {numeral (graphData?.vendorWithMaxAmount?.total_amount).format("0,000.00")}
        </p>
        <p className="fw-bold">
          {" "}
          Total {translations.forms.formFields.amount[language]} total : CAD
          {numeral (graphData?.totalAmount).format("0,000.00")}
        </p>
      </div>
    </div>
  );
};

export default SalesOverview;
