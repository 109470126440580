import { Formik, Form, Field, ErrorMessage } from "formik";
import { useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import * as Yup from "yup";
import logo from "./assets/Nexus_logo@3x.png";
import { toast } from "react-toastify";
import { ApiUrl } from "./ApiUrl";
import Loader from "./loader";

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  // useLocation gives you access to the current URL location
  const location = useLocation();
  // Get the value of the 'reset_token' query parameter
  const queryParams = new URLSearchParams(location.search);
  const reset_token = queryParams.get("reset_token");

  const validationSchema = Yup.object().shape({
    // email: Yup.string()
    //   .email("Invalid email address")
    //   .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /[A-Z]/,
        "Password must contain at least one uppercase character"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /[A-Z]/,
        "Password must contain at least one uppercase character"
      ),
  });
 
  const handleSubmit = async (values, { resetForm }) => {
    
    try {
      setIsLoading(true);
      const res = await fetch(
        ApiUrl + "/api/user/reset-password/" + reset_token,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            password: values.password,
            password_confirmation: values.confirmPassword,
          }),
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data.message);
        navigate("/");
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error);
    }
  };

  return (
    <div>
      {isLoading && (
        <div className="splash-screen">
          <Loader />
        </div>
      )}
      <div className="ResetPassword form-wrapper">
        <main className="form-signin mx-auto">
          <Formik
            initialValues={{
              // email: "",
              password: "",
              confirmPassword: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="d-flex justify-content-center">
                <Link to="/">
                  <img className="mb-4 logo" width="100px" src={logo} alt="" />
                </Link>
              </div>
              <div className="form-floating mt-3">
                <Field
                  type="password"
                  name="password"
                  className="form-control"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error text-danger"
                />
                <label htmlFor="floatingPassword">Password</label>
              </div>
              <div className="form-floating mt-3">
                <Field
                  type="password"
                  name="confirmPassword"
                  className="form-control"
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="error text-danger"
                />
                <label htmlFor="floatingPassword">Confirm Password</label>
              </div>
              <button
                className="w-100 admin-btn btn-lg btn-primary mt-3"
                type="submit"
                disabled={isLoading}
              >
                Reset Password
              </button>
            </Form>
          </Formik>
        </main>
      </div>
    </div>
  );
};

export default ResetPassword;
