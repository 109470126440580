import { Formik, Form, Field, ErrorMessage } from "formik";
import { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import logo from "./assets/Nexus_logo@3x.png";
import { toast } from "react-toastify";
import { ApiUrl } from "./ApiUrl";
import Loader from "./loader";

const ConfirmEmail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    // password: Yup.string()
    //   .required("Password is required")
    //   .min(8, "Password must be at least 8 characters")
    //   .matches(/[A-Z]/, "Password must contain at least one uppercase character"),
    // confirmPassword: Yup.string()
    //   .oneOf([Yup.ref("password"), null], "Passwords must match")
    //   .required("Confirm Password is required")
    //   .min(8, "Password must be at least 8 characters")
    //   .matches(/[A-Z]/, "Password must contain at least one uppercase character"),
  });


  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    // console.log(values)
    try {
      const res = await fetch(ApiUrl + "/api/user/send-reset-password-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: values.email,
        }),
      });
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error);
    }
  };

  return (
    <div className="ResetPassword form-wrapper">
      {isLoading ? (
        <div className={`splash-screen`}>
          <Loader />
        </div>
      ) : (
        <main className="form-signin mx-auto">
          <Formik
            initialValues={{
              email: "",
              // password: "",
              // confirmPassword: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="d-flex justify-content-center">
                <Link to="/">
                  <img className="mb-4 logo" width="100px" src={logo} alt="" />
                </Link>
              </div>
              <div className="form-floating mt-3">
                <Field type="email" name="email" className="form-control" />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error text-danger"
                />
                <label htmlFor="floatingInput ">Email address</label>
              </div>
              <button
                className="w-100 admin-btn btn-lg btn-primary mt-3"
                type="submit"
              >
                Confirm Email
              </button>
            </Form>
          </Formik>
        </main>
      )}
    </div>
  );
};

export default ConfirmEmail;
