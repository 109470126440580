import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { ApiUrl } from "./ApiUrl";
// import PaginatedItems from "./Pagination";
import ModalAddDealer_CreateEvent from "./ModalAddDealer_CreateEvent";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";
import AddDealershipModal from "./AddDealershipModal";
import debounce from "lodash/debounce";
const Dealership = ({
  setDisplayComponent,
  setDealerTrigger,
  setIsLoading,
  isDarkMode,
  users,
}) => {
  const token = localStorage.getItem("token");
  const [dealerships, setDealerships] = useState([]);
  const [dealershipSearch, setDealershipSearch] = useState();
  const [editDealerID, setEditDealerID] = useState();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [pageCache, setPageCache] = useState({});
  const [searchCache, setSearchCache] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState();
  const [activeSearch, setActiveSearch] = useState(false);
  const [key, setKey] = useState("");
  const [brands, setBrands] = useState();
  // Create state to track deleteClicked for each item
  const [deleteStates, setDeleteStates] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [oldData, setOldData] = useState(null);
  const [editTrigger, setEditTrigger] = useState(false);
  const [allowScrollFetch, setAllowScrollFetch] = useState(true);
  const repOptions =
    users &&
    users.representatives.length > 0 &&
    users.representatives.map((user) => ({
      value: user.id,
      label: user.name,
    }));
  const { language } = useLanguage();
  const pageTranslations = translations["dealership"];
  const bottomMarkerRef = useRef(null);
  const containerRef = useRef(null);
  let page = 1;

  //   number: 405,
  //   Dealer: "Val D'Or Chrysler",
  //   Representative: "Vincent Mercier",
  //   Week: 27,
  //   drive: "https://drive.google.com/drive/folders/1roc6fyoGWS34MBbzqao0Rqx78N80vAUa",
  // }, {
  //   number: 407,
  //   Dealer: "Granby Toyota",
  //   Representative: "Représentant",
  //   Week: 27,
  //   drive: "https://drive.google.com/drive/folders/1roc6fyoGWS34MBbzqao0Rqx78N80vAUa",
  // }

  useEffect(() => {
    const fetchData = async () => {
      if (pageCache[pageNo]) {
        // Data already in cache, no need for API call
        const { cachedResponse, cachedTotal } = pageCache[pageNo];
        setDealerships(cachedResponse);
        setDealershipSearch(cachedResponse);
        setTotal(cachedTotal);
      } else {
        setIsLoading(true);
        try {
          const res = await fetch(
            ApiUrl + "/api/user/get-all-dealership?page=" + pageNo,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          // console.log("Get Dealership res: ", res);
          const data = await res.json();
          // console.log("Get Dealership data: ", data)
          // console.log(result)
          if (data.status === "success") {
            const newData = data.list_of_dealership.data;
            const newTotal = data.list_of_dealership.total;
            // Update the cache with new data
            setPageCache((prevCache) => ({
              ...prevCache,
              [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
            }));
            // Set state with new data
            setDealerships(newData);
            setDealershipSearch(newData);
            setTotal(newTotal);

            setDeleteStates(newData.map(() => false));
          } else {
            toast.error(data.message);
          }
          setIsLoading(false);
        } catch (err) {
          toast.error("Something went wrong");
        } finally {
        }
      }
    };
    if (!activeSearch) {
      fetchData();
    }
  }, [pageNo, pageCache, dealerships]);

  useEffect(() => {
    const getBrands = async () => {
      try {
        const res = await fetch(ApiUrl + "/api/user/get-brands", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await res.json();

        if (data.status === "success") {
          setBrands(data.list_of_brands);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.error("Error fetching dealerships:", error);
        // Handle error or show a toast if needed
      }
    };
    getBrands();
  }, [token]);

  const handleDelete = async (id, index) => {
    setIsLoading(true);
    const page =
      dealerships.length > 1 ? pageNo : pageNo - 1 > 0 ? pageNo - 1 : pageNo;
    const searchParam = key ? "&search_parameter=" + key : "";
    try {
      const res = await fetch(
        ApiUrl + "/api/user/delete-dealership?page=" + page + "" + searchParam,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            id: id,
          }),
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        toast.success("Dealership deleted successful");
        const newData = data.list_of_dealership.data;
        const newTotal = data.list_of_dealership.total;

        setDealerTrigger((prev) => !prev);
        setDealerships(newData);
        setTotal(newTotal);
        setPageCache({
          [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
        });
        if (key) {
          setSearchCache({
            [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
          });
        }

        if (activeSearch) {
          setPageNo(
            dealerships.length > 1
              ? pageNo
              : pageNo - 1 > 0
              ? pageNo - 1
              : pageNo
          );
        }

        // Update delete state for the current item
        const newDeleteStates = [...deleteStates];
        newDeleteStates[index] = false;
        setDeleteStates(newDeleteStates);
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const handleSearch = async (pageNo, initial) => {
    if (!initial && searchCache[pageNo]) {
      // Data already in cache, no need for API call
      const { cachedResponse, cachedTotal } = searchCache[pageNo];
      setDealerships(cachedResponse);
      setTotal(cachedTotal);
    } else {
      // setFilterLoading(true);
      // setGetLoading(true);

      try {
        const res = await fetch(
          ApiUrl + "/api/user/search-dealership?page=" + pageNo,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              search_parameter: key,
            }),
          }
        );
        const data = await res.json();
        if (data.status === "success") {
          setActiveSearch(true);

          const newData = data.list_of_dealership.data;
          const newTotal = data.list_of_dealership.total;
          // Update the cache with new data
          setSearchCache((prevCache) => ({
            ...prevCache,
            [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
          }));
          // Set state with new data
          setDealerships(newData);
          setDealershipSearch(newData);

          setTotal(newTotal);
          // if (pageNo === 1) toast.success("Dealerships search successful");
          // setFilterLoading(false);
          // setGetLoading(false);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        toast.error("Something went wrong");
        // setFilterLoading(false);
        // setGetLoading(false);
      }
    }
  };

  const getScrollData = async (pageNo) => {
    // setFilterLoading(true);
    // setGetLoading(true);
    page = page + 1;

    try {
      const res = await fetch(
        ApiUrl + "/api/user/search-dealership?page=" + page,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            search_parameter: key,
          }),
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        // setActiveSearch(true);

        setPageNo((prev) => prev + 1);
        const newData = data.list_of_dealership.data;
        // const newTotal = data.list_of_dealership.total;
        console.log(dealerships);
        if (data.list_of_dealership.data.length > 0)
          setDealerships((prevDealerships) => [...prevDealerships, ...newData]);

        // Update the cache with new data
        // setSearchCache((prevCache) => ({
        //   ...prevCache,
        //   [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
        // }));
        // Set state with new data
        // setDealershipSearch(newData);

        // setTotal(newTotal);
        // if (pageNo === 1) toast.success("Dealerships search successful");
        // setFilterLoading(false);
        // setGetLoading(false);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Something went wrong");
      // setFilterLoading(false);
      // setGetLoading(false);
    }
  };

  useEffect(() => {
    if (editDealerID && dealerships) {
      const updatedDealerships =
        dealerships &&
        dealerships.length > 0 &&
        dealerships.map((dealer) => {
          if (dealer.id === editDealerID) {
            // Update t`he dealer name for the specific dealer ID

            return dealer;
          }
          return dealer;
        });

      // Set the updated dealerships array in the state
      setDealerships(updatedDealerships);
      setPageCache({
        [pageNo]: { cachedResponse: updatedDealerships, cachedTotal: total },
      });
      if (key) {
        setSearchCache({
          [pageNo]: { cachedResponse: updatedDealerships, cachedTotal: total },
        });
      }
      setDealerTrigger((prev) => !prev);
    }
  }, [editTrigger]);

  const handleClearFilters = () => {
    const { cachedResponse, cachedTotal } = pageCache[1];
    setPageNo(1);
    setActiveSearch(false);
    setTotal(cachedTotal);
    setSearchCache({});
    setDealerships(cachedResponse);
    setKey("");
    // toast.success("Dealerships search cleared");
  };

  const handlePageChange = (page) => {
    if (activeSearch) handleSearch(page);
  };

  const SCROLL_TRIGGER_OFFSET = 1000; // Pixels before the top or bottom marker to trigger the fetch
  // const SCROLL_THRESHOLD_PERCENT = 0.1;

  const fetchDataOnScroll = async (direction) => {
    if (isFetching || !allowScrollFetch) return; // Prevent fetch if already fetching or scroll fetching is disabled

    setIsFetching(true);
    try {
      console.log("Fetching data for lower dealers.");
      await getScrollData(pageNo);
    } finally {
      setIsFetching(false);
    }
  };

  // Memoize the debounced function to prevent unnecessary re-creation
  const debouncedHandleScroll = useMemo(
    () =>
      debounce(() => {
        if (!allowScrollFetch) return;

        const container = containerRef.current;
        const bottomMarker = bottomMarkerRef.current;

        if (container && bottomMarker) {
          const containerRect = container.getBoundingClientRect();
          const bottomMarkerRect = bottomMarker.getBoundingClientRect();

          // Calculate distance to top and bottom markers

          const distanceToBottomMarker =
            bottomMarkerRect.bottom - containerRect.bottom;

          // Fetch data if within offset range
          if (distanceToBottomMarker < SCROLL_TRIGGER_OFFSET) {
            fetchDataOnScroll("down");
          }
        }
      }, 300), // Debounce time (adjust as needed)
    [allowScrollFetch] // Dependency array includes scroll flag
  );

  // Use `useCallback` to wrap the debounced function
  const handleScroll = useCallback(debouncedHandleScroll, [
    debouncedHandleScroll,
  ]);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      container.addEventListener("scroll", handleScroll);

      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageCache((prevCache) => ({
        ...prevCache,
        pageNo: pageNo,
      }));
      setPageNo(1);
      setSearchCache({});
      handleSearch(1, true);
    }, 500);

    return () => clearTimeout(timer);
  }, [key]);

  return (
    <div className="Events">
      <div class="container maincont">
        <div id="app" class="appcountainer2">
          <div className="d-flex justify-content-between align-items-center py-2">
            <div>
              <h2 id="h1" className={`${isDarkMode ? "darkthead" : ""}`}>
                {pageTranslations.title[language]}{" "}
              </h2>
            </div>
            {userData &&
              userData.roles[0].permissions.some(
                (item) => item.name === "Create Dealership"
              ) && (
                <div className="d-grid gap-2">
                  {/* <Link to='/create-user' style={{ textDecoration: "none" }}> */}
                  <button
                    type="button"
                    className=" btn admin-btn btn-primary d-flex align-items-center gap-1"
                    data-bs-toggle="modal"
                    data-bs-target="#myModalCon"
                  >
                    <i className="bi bi-person-add"></i>{" "}
                    {translations.quickCreationModal.createDealership[language]}
                  </button>
                  {/* </Link> */}
                </div>
              )}
          </div>
          <div class="headersec">
            <div class="mb-2 d-flex">
              <input
                type="text"
                autocomplete="off"
                className={`form-control ${isDarkMode ? "dark-mode" : ""}`}
                style={{
                  borderColor: "#dee2e6",
                  backgroundColor: isDarkMode ? "transparent" : "",
                  color: isDarkMode ? "#ffffff" : "#000000",
                }}
                id="searchInput"
                placeholder={
                  translations.commonFields.searchbar.search[language]
                }
                value={key}
                onChange={(e) => {
                  setKey(e.target.value);
                }}
              />
              {activeSearch && (
                <button
                  className="bi bi-x-lg btn searchBtn"
                  style={{ padding: "6px 8px" }}
                  onClick={handleClearFilters}
                ></button>
              )}
              <button
                className="bi bi-search btn searchBtn"
                style={{ padding: "6px 8px" }}
                onClick={() => {
                  setPageCache((prevCache) => ({
                    ...prevCache,
                    pageNo: pageNo,
                  }));
                  setPageNo(1);
                  setSearchCache({});
                  if (key) {
                    handleSearch(1, true);
                  }
                }}
              />
            </div>
          </div>
          <div
            // class="bodysecsearch"
            // style={{
            //   display: "flex",
            //   flexDirection: "column",
            //   justifyContent: "space-between",
            // }}
            ref={containerRef}
            style={{
              display: "flex",
              flexDirection: "column",
              overflowX: "auto",
              justifyContent: "space-between",
              height: "calc(100vh - 260px)",
            }}
          >
            <table class="table table-hover text-center align-middle">
              <thead style={{ position: "sticky", top: "0", zIndex: "1" }}>
                <tr>
                  <th
                    scope="col"
                    className={`${isDarkMode ? "darkthead" : ""}`}
                  >
                    #
                  </th>
                  <th
                    scope="col"
                    className={`${isDarkMode ? "darkthead" : ""}`}
                  >
                    {pageTranslations.dealershipName[language]}{" "}
                  </th>
                  <th
                    scope="col"
                    className={`${isDarkMode ? "darkthead" : ""}`}
                  >
                    {translations.quickCreationModal.brandName[language]}{" "}
                  </th>
                  {/* <th scope="col">Representative</th> */}
                  {/* <th scope="col">Week</th> */}
                  {/* <th scope="col"></th> */}
                  <th
                    scope="col"
                    colSpan={2}
                    className={`${isDarkMode ? "darkthead" : ""}`}
                  >
                    Actions
                  </th>
                  {/* <th scope="col"></th> */}
                </tr>
              </thead>
              <tbody id="">
                {dealerships &&
                  dealerships.length > 0 &&
                  dealerships.map((dealer, index) => {
                    // console.log(event.number);
                    return (
                      <tr class="result-box">
                        <th class="custID" scope="row">
                          {dealer.id}
                        </th>
                        <td class="dlrname">{dealer?.name}</td>
                        <td class="dlrname">{dealer?.brand_name}</td>
                        {/* <td class="salesrep">{dealer.sales_representative}</td> */}
                        {/* <td class="semainesearch">Week: {dealer.week}</td> */}
                        {/* <td>
                          <a
                            type="button"
                            target="_blank"
                            class="btn btn-light drive-button"
                            href={dealer.drive_link}
                          >
                            <i class="bi bi-folder"></i> Drive
                          </a>
                        </td> */}
                        <td style={{ width: "170px" }}>
                          <button
                            type="button"
                            class="btn btn-light edit-button"
                            data-customer-id="405"
                            data-bs-toggle="modal"
                            data-bs-target="#AddDealerModal"
                            onClick={() => {
                              setEditDealerID(dealer.id);
                              setOldData(dealer);
                              // setDisplayComponent("edit-event");
                              // setEditEventID(event.id)
                              // window.history.pushState(
                              //   {
                              //     id: "gallery",
                              //     randomData: window.Math.random(),
                              //   },
                              //   "title",
                              //   "/event-portal?page=edit-event&parent_id=" +
                              //   dealer.id
                              // );
                            }}
                          >
                            <i class="bi bi-pencil"></i>{" "}
                            {translations.commonFields.editBtn[language]}
                          </button>
                        </td>
                        {userData &&
                          userData.roles[0].permissions.some(
                            (item) => item.name === "Delete Dealership"
                          ) && (
                            <td style={{ width: "170px" }}>
                              <div class="btn-group" role="group">
                                {deleteStates[index] ? (
                                  <>
                                    <button
                                      type="button"
                                      class="btn btn-success delete-button"
                                      data-customer-id="405"
                                      onClick={() =>
                                        handleDelete(dealer.id, index)
                                      }
                                    >
                                      {
                                        translations.commonFields.confirm[
                                          language
                                        ]
                                      }
                                    </button>
                                    <button
                                      type="button"
                                      class="btn btn-secondary cancel-button"
                                      onClick={() =>
                                        setDeleteStates((prevStates) => {
                                          const newStates = [...prevStates];
                                          newStates[index] = false;
                                          return newStates;
                                        })
                                      }
                                    >
                                      {
                                        translations.commonFields.cancel[
                                          language
                                        ]
                                      }
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    type="button"
                                    class="btn btn-danger before-delete-button"
                                    dataset-button-state="delete"
                                    onClick={() =>
                                      setDeleteStates((prevStates) => {
                                        const newStates = [...prevStates];
                                        newStates[index] = true;
                                        return newStates;
                                      })
                                    }
                                  >
                                    <i class="bi bi-trash"></i>{" "}
                                    {translations.commonFields.delete[language]}
                                  </button>
                                )}
                              </div>
                              {/* {!deleteClicked && <div>
                            <button
                              type="button"
                              class="btn btn-danger before-delete-button"
                            >
                              Delete
                            </button>
                          </div>}
                           */}
                            </td>
                          )}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <div ref={bottomMarkerRef} style={{ height: "1px" }} />
          </div>
          {/* {total !== 0 && total > 10 && (
            <PaginatedItems
              total={total}
              pageNo={pageNo}
              setPageNo={setPageNo}
              handlePageChange={handlePageChange}
              isDarkMode={isDarkMode}
            />
          )} */}
        </div>
        <AddDealershipModal
          setDealerTrigger={setDealerTrigger}
          setIsLoading={setIsLoading}
          // setSelectedDealership={setSelectedDealership}
          dealerships={dealerships}
          users={users}
          quickAdd={false}
        />
        <ModalAddDealer_CreateEvent
          dealerships={dealerships}
          setDealerships={setDealerships}
          editID={editDealerID}
          isEdit={true}
          oldData={oldData}
          setOldData={setOldData}
          setEditTrigger={setEditTrigger}
          setIsLoading={setIsLoading}
          brands={brands}
          isDarkMode={isDarkMode}
          repOptions={repOptions}
        />
      </div>
    </div>
  );
};

export default Dealership;
