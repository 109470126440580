import Loader from "./loader";

const EditClientForm = () => {
  return (
    <Loader/>
    /*<div className="EditClientForm">
      <div class="container maincont">
        <div id="app" class="appcountainer">
          <div class="edit-form-form">
            <div class="headersec">
              <nav class="navbar navbar-expand-lg navbar-light">
                <div class="container-fluid">
                  <h2 class="navbar-brand">Edit: Customer Form</h2>
                  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                  </button>
                  <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                      <li class="nav-item">
                        <a class="btn admin-btn btn-primary" data-bs-toggle="collapse" href="#multiCollapseDate" role="button" aria-expanded="false" aria-controls="multiCollapseDate"><i class="bi bi-calendar"></i> Dates</a>
                      </li>

                      <li class="nav-item">
                        <button class="btn admin-btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#multiCollapseInfosvente" aria-expanded="false" aria-controls="multiCollapseInfosvente"><i class="bi bi-info-circle"></i> Sales Info</button>
                      </li>
                      <li class="nav-item">
                        <button class="btn admin-btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#multiCollapseInfoscontact" aria-expanded="false" aria-controls="multiCollapseInfoscontact"><i class="bi bi-person-lines-fill"></i> Contact</button>
                      </li>
                      <li class="nav-item">
                        <button class="btn admin-btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#multiCollapseInfosequipe" aria-expanded="false" aria-controls="multiCollapseInfosequipe"><i class="bi bi-person-badge"></i> Team</button>
                      </li>
                      <li class="nav-item">
                        <button class="btn admin-btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#multiCollapseInfospromos" aria-expanded="false" aria-controls="multiCollapseInfospromos"><i class="bi bi-stars"></i> Promos and More</button>
                      </li>
                      <li class="nav-item">
                        <button class="btn admin-btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target=".multi-collapse" aria-expanded="false" aria-controls="multiCollapseDate multiCollapseOptions multiCollapseInfosvente multiCollapseInfoscontact multiCollapseInfosequipe multiCollapseInfospromos"><i class="bi bi-folder2-open"></i> Open All</button>
                      </li>
                    </ul>
                  </div>
                </div>

              </nav>
            </div>
            <div class="bodysectab">

              <div class="row">

                <div class="col">
                  <div class="mb-3">
                    <label for="entry-id" class="form-label">ID</label>
                    <input type="text" class="form-control" id="entry-id" readonly="" disabled="" />
                  </div>
                </div>

                <div class="col">
                  <div class="mb-3">
                    <label for="dealership-name" class="form-label">Dealer</label>
                    <input type="text" class="form-control" id="dealership-name" readonly="" disabled="" />
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3">
                    <label for="Package" class="form-label">Package</label>
                    <input type="text" class="form-control" id="Package" readonly="" disabled="" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">

                  <div class="mb-3">
                    <label class="form-label">Representative</label>
                    <select class="form-select" aria-label="Representative" id="rep-name" readonly="" disabled="">
                      <option>Représentant</option>
                      <option>François Mercier</option>
                      <option>Jean-Francois Coulombe</option>
                      <option>Serge Beaulé</option>
                      <option>Vincent Mercier</option>
                      <option>Yves Chiasson</option>
                    </select>
                  </div>
                </div>

                <div class="col">

                  <div class="mb-3">
                    <label for="last-sale-date" class="form-label">Date of last sale</label>
                    <input type="date" class="form-control" id="last-sale-date" readonly="" disabled="" />
                  </div>
                </div>


                <div class="col">
                  <div class="mb-3">
                    <label for="nb-ventes-annuelles" class="form-label">Digital</label>
                    <input type="text" class="form-control" id="nb-ventes-annuelles" readonly="" disabled="" />
                  </div>
                </div>
              </div>

              <div class="row align-middle">
                <div class="collapse multi-collapse" id="multiCollapseDate">
                  <div class="card card-body">
                    <h2>Dates</h2>

                    <div class="row">
                      <div class="col-2 align-middle">
                        <div class="mb-3">
                          <p>Title</p>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="mb-3">
                          <p>1st Day</p>
                        </div>
                      </div>

                      <div class="col-1">
                        <div class="mb-3">
                          <p>Days</p>
                        </div>
                      </div>

                      <div class="col-5">
                        <div class="mb-3">
                          <p>Event Dates</p>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="mb-3">
                          <p>Week</p>
                        </div>
                      </div>

                    </div>

                    <div class="row align-middle">

                      <div class="col-2 align-middle">
                        <div class="mb-3">
                          <p>1st Event</p>
                        </div>
                      </div>

                      <div class="col-2">
                        <div class="mb-3">
                          <input type="date" class="form-control" id="date_day_1" onchange="datesCalculator()" readonly="" disabled="" />
                        </div>
                      </div>

                      <div class="col-1">
                        <select class="form-select" aria-label="Default select example" id="nb_day_1" onchange="datesCalculator()" readonly="" disabled="">
                          <option selected="">Number of days</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                      <div class="col-5">
                        <div class="mb-3">
                          <textarea class="form-control" id="date_event_1" rows="2" readonly="" disabled=""></textarea>
                        </div>
                      </div>

                      <div class="col-2">
                        <div class="mb-3">
                          <input type="text" class="form-control" id="event-1-Week" readonly="" disabled="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row" style={{ display: "none" }}>
                <div className="collapse multi-collapse" id="multiCollapseOptions">
                  <div className="card card-body">
                    <h2>Conquest</h2>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="opt_conq" className="form-label">Type<span className="badge rounded-pill bg-success bi bi-info-circle-fill" style={{ marginLeft: "10px" }}> </span><tool-tip role="tooltip" tip-position="top">Previous data: There was no data in the original file.</tool-tip></label>
                          <select className="form-select" aria-label="Default select example" id="opt_conq">
                            <option value="Conquest" selected="">Conquest</option>
                          </select>
                        </div>
                      </div>

                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="opt-conquetes-nb-pros" className="form-label">Required Quantity<span className="badge rounded-pill bg-success bi bi-info-circle-fill" style={{ marginLeft: "10px" }}> </span><tool-tip role="tooltip" tip-position="top">Previous data: 1000</tool-tip></label>
                          <input/type="text" className="form-control" id="opt-conquetes-nb-pros" />
                        </div>
                      </div>

                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="opt-conquetes-nb-comm" className="form-label">To Order<span className="badge rounded-pill bg-success bi bi-info-circle-fill" style={{ marginLeft: "10px" }}> </span><tool-tip role="tooltip" tip-position="top">Previous data: There was no data in the original file. </tool-tip></label>
                          <input type="text" className="form-control" id="opt-conquetes-nb-comm" />
                        </div>
                      </div>

                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="zone_cib" className="form-label">Postal Code<span className="badge rounded-pill bg-success bi bi-info-circle-fill" style={{ marginLeft: "10px" }}> </span><tool-tip role="tooltip" tip-position="top">Previous data: 500</tool-tip></label>
                          <input type="text" className="form-control" id="zone_cib" />
                        </div>
                      </div>

                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="indic_reg" className="form-label">Regional Codes<span className="badge rounded-pill bg-success bi bi-info-circle-fill" style={{ marginLeft: "10px" }}> </span><tool-tip role="tooltip" tip-position="top">Previous data: 418</tool-tip></label>
                          <input type="text" className="form-control" id="indic_reg" />
                        </div>
                      </div>

                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="rev_cib" className="form-label">Targeted Income<span className="badge rounded-pill bg-success bi bi-info-circle-fill" style={{ marginLeft: "10px" }}> </span><tool-tip role="tooltip" tip-position="top">Previous data: 50k and more</tool-tip></label>
                          <select className="form-select" id="rev_cib">
                            <option selected=""> </option>
                            <option>25k and more</option>
                            <option>50k and more</option>
                            <option>75k and more</option>
                            <option>100k and more</option>
                          </select>
                        </div>
                      </div>

                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="age_cib" className="form-label">Targeted Age<span className="badge rounded-pill bg-success bi bi-info-circle-fill" style={{ marginLeft: "10px" }}> </span><tool-tip role="tooltip" tip-position="top">Previous data: 35 to 65 years</tool-tip></label>
                          <select className="form-select" id="age_cib">
                            <option selected=""> </option>
                            <option>18 to 65 years</option>
                            <option>35 to 65 years</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="lnnte_acc_abb" className="form-label">Subscription Access Number<span className="badge rounded-pill bg-success bi bi-info-circle-fill" style={{ marginLeft: "10px" }}> </span><tool-tip role="tooltip" tip-position="top">Previous data: There was no data in the original file. </tool-tip></label>
                          <input type="text" className="form-control" id="lnnte_acc_abb" />
                        </div>
                      </div>

                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="lnnte_acc_key" className="form-label">Download Key<span className="badge rounded-pill bg-success bi bi-info-circle-fill" style={{ marginLeft: "10px" }}> </span><tool-tip role="tooltip" tip-position="top">Previous data: There was no data in the original file. </tool-tip></label>
                          <input type="text" className="form-control" id="lnnte_acc_key" />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm" style={{ marginBottom: "10px" }}>
                        <button type="submit" className="btn admin-btn btn-primary" id="form-conq">Generate Form</button>
                      </div>

                      <hr />
                      <div className="row">

                        <div className="col">
                          <div className="mb-3">
                            <label htmlFor="opt-conquetes-nb-postal" className="form-label">Number of Postal Sends<span className="badge rounded-pill bg-success bi bi-info-circle-fill" style={{ marginLeft: "10px" }}> </span><tool-tip role="tooltip" tip-position="top">Previous data: 10000</tool-tip></label>
                            <input type="text" className="form-control" id="opt-conquetes-nb-postal" />
                          </div>
                        </div>
                        <div className="col">
                          <div className="mb-3">
                            <label htmlFor="ter_cib" className="form-label">Targeted Territory<span className="badge rounded-pill bg-success bi bi-info-circle-fill" style={{ marginLeft: "10px" }}> </span><tool-tip role="tooltip" tip-position="top">Previous data: g0a - goh</tool-tip></label>
                            <input type="text" className="form-control" id="ter_cib" />
                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div class="row">
                <div class="collapse multi-collapse" id="multiCollapseInfosvente">
                  <div class="card card-body">
                    <h2>Sales Information</h2>

                    <div className="row">

                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="titre-de-la-vente" className="form-label">Sales Title<span className="badge rounded-pill bg-success bi bi-info-circle-fill" style={{ marginLeft: "10px" }}> </span><tool-tip role="tooltip" tip-position="top">Previous data: la tomba</tool-tip></label>
                          <input type="text" className="form-control" id="titre-de-la-vente" />
                        </div>
                      </div>

                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="vehicule-a-avant" className="form-label">Vehicle in Front<span className="badge rounded-pill bg-success bi bi-info-circle-fill" style={{ marginLeft: "10px" }}> </span><tool-tip role="tooltip" tip-position="top">Previous data: acura</tool-tip></label>
                          <input type="text" className="form-control" id="vehicule-a-avant" />
                        </div>
                      </div>

                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="coup-de-coeur" className="form-label">Favorite<span className="badge rounded-pill bg-success bi bi-info-circle-fill" style={{ marginLeft: "10px" }}> </span><tool-tip role="tooltip" tip-position="top">Previous data: tucson</tool-tip></label>
                          <input type="text" className="form-control" id="coup-de-coeur" />
                        </div>
                      </div>

                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="langue-des-visuelles" className="form-label">Language of Visuals<span className="badge rounded-pill bg-success bi bi-info-circle-fill" style={{ marginLeft: "10px" }}> </span><tool-tip role="tooltip" tip-position="top">Previous data: Francais</tool-tip></label>
                          <select className="form-select" aria-label="Default select example" id="langue-des-visuelles">
                            <option selected=""></option>
                            <option value="Francais">Français</option>
                            <option value="Anglais">Anglais</option>
                            <option value="Bilingue">Bilingue</option>
                          </select>
                        </div>
                      </div>

                    </div>
                    <hr />
                    <div className="row">

                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="plage-hor-des-rdv" className="form-label">Appointment Time Slots at:</label>
                          <select className="form-select" aria-label="Default select example" id="plage-hor-des-rdv">
                            <option selected=""></option>
                            <option value="30">30</option>
                            <option value="60">60</option>
                            <option value="90">90</option>
                            <option value="120">120</option>
                          </select>
                        </div>
                      </div>

                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="rdv-par-plage-hor" className="form-label">Appointments per Time Slot:<span className="badge rounded-pill bg-success bi bi-info-circle-fill" style={{ marginLeft: "10px" }}> </span><tool-tip role="tooltip" tip-position="top">Previous data: 5</tool-tip></label>
                          <select className="form-select" aria-label="Default select example" id="rdv-par-plage-hor">
                            <option selected=""></option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                          </select>
                        </div>
                      </div>

                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="heures-ouverture" className="form-label">Opening Hours (With Last Appointment)<span className="badge rounded-pill bg-success bi bi-info-circle-fill" style={{ marginLeft: "10px" }}> </span><tool-tip role="tooltip" tip-position="top">Previous data: 9h - 20h</tool-tip></label>
                          <input type="text" className="form-control" id="heures-ouverture" />
                        </div>
                      </div>

                    </div>

                    <div className="row">

                      <div className="col-6">
                        <div className="mb-3">
                          <label htmlFor="ach-vec-occ" className="form-label">Buyer of used vehicles without a new purchase or lease?<span className="badge rounded-pill bg-success bi bi-info-circle-fill" style={{ marginLeft: "10px" }}> </span><tool-tip role="tooltip" tip-position="top">Previous data: Yes</tool-tip></label>
                          <select className="form-select" aria-label="Default select example" id="ach-vec-occ">
                            <option selected=""></option>
                            <option value="Oui">Oui</option>
                            <option value="Non">Non</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="mb-3">
                          <label htmlFor="ass-rdv" className="form-label">Appointment assignment by advisor?<span className="badge rounded-pill bg-success bi bi-info-circle-fill" style={{ marginLeft: "10px" }}> </span><tool-tip role="tooltip" tip-position="top">Previous data: No Assignment</tool-tip></label>
                          <select className="form-select" aria-label="Default select example" id="ass-rdv">
                            <option selected=""></option>
                            <option value="Assignation">Assignation</option>
                            <option value="Pas Assignation">Pas Assignation</option>
                          </select>
                        </div>
                      </div>

                    </div>
                    <hr />

                    <div className="row">

                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="accueille-les-clients" className="form-label">Welcomes clients:</label>
                          <select className="form-select" aria-label="Default select example" id="accueille-les-clients">
                            <option selected=""></option>
                            <option value="Directeur d’évènement">Event Director</option>
                            <option value="Employé de la concession">Dealership Employee</option>
                            <option value="Réception">Reception</option>
                          </select>
                        </div>
                      </div>

                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="nom-personne-accueil" className="form-label">Name of the person at the reception <span className="badge rounded-pill bg-success bi bi-info-circle-fill" style={{ marginLeft: "10px" }}> </span><tool-tip role="tooltip" tip-position="top">Previous data: Francois Mercier</tool-tip></label>
                          <input type="text" className="form-control" id="nom-personne-accueil" />
                        </div>
                      </div>

                    </div>

                    <hr />

                    <div className="row" style={{ display: "none" }}>
                      <h4>Balloons showroom</h4>

                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="qte-ballon" className="form-label">Quantity<span className="badge rounded-pill bg-success bi bi-info-circle-fill" style={{ marginLeft: "10px" }}> </span><tool-tip role="tooltip" tip-position="top">Previous data: No data in the original file. </tool-tip></label>
                          <input type="text" className="form-control" id="qte-ballon" />
                        </div>
                      </div>

                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="clr-ballon" className="form-label">Colors<span className="badge rounded-pill bg-success bi bi-info-circle-fill" style={{ marginLeft: "10px" }}> </span><tool-tip role="tooltip" tip-position="top">Previous data: No data in the original file. </tool-tip></label>
                          <input type="text" className="form-control" id="clr-ballon" />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="collapse multi-collapse" id="multiCollapseInfoscontact">
                  <div class="card card-body">
                    <h2>Contact</h2>

                    <div class="row">
                      <h4>Person in Charge | Theme-Promotions</h4>

                      <div class="col">
                        <div class="mb-3">
                          <label for="rep-theme-promos-nom" class="form-label">Name<span class="badge rounded-pill bg-success bi bi-info-circle-fill" style={{marginLeft:"10px"}}> </span><tool-tip role="tooltip" tip-position="top">Old data: Vincent Mercier</tool-tip></label>
                          <input type="text" class="form-control" id="rep-theme-promos-nom" />
                        </div>
                      </div>

                      <div class="col">
                        <div class="mb-3">
                          <label for="rep-theme-promos-courriel" class="form-label">Email<span class="badge rounded-pill bg-success bi bi-info-circle-fill" style={{marginLeft:"10px"}}> </span><tool-tip role="tooltip" tip-position="top">Old data: vmercier@fidgi.ca</tool-tip></label>
                          <input type="text" class="form-control" id="rep-theme-promos-courriel" />
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="rep-theme-promos-tel" class="form-label">Phone<span class="badge rounded-pill bg-success bi bi-info-circle-fill" style={{marginLeft:"10px"}}> </span><tool-tip role="tooltip" tip-position="top">Old data: 418-443-2233</tool-tip></label>
                          <input type="text" class="form-control" id="rep-theme-promos-tel" />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <h4>Person in Charge | Lists</h4>

                      <div class="col">
                        <div class="mb-3">
                          <label for="rep-listes-nom" class="form-label">Name<span class="badge rounded-pill bg-success bi bi-info-circle-fill" style={{marginLeft:"10px"}}> </span><tool-tip role="tooltip" tip-position="top">Old data: Francois Mercier</tool-tip></label>
                          <input type="text" class="form-control" id="rep-listes-nom" />
                        </div>
                      </div>

                      <div class="col">
                        <div class="mb-3">
                          <label for="rep-listes-courriel" class="form-label">Email<span class="badge rounded-pill bg-success bi bi-info-circle-fill" style={{marginLeft:"10px"}}> </span><tool-tip role="tooltip" tip-position="top">Old data: fmercier@fidgi.ca</tool-tip></label>
                          <input type="text" class="form-control" id="rep-listes-courriel" />
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="rep-listes-tel" class="form-label">Phone<span class="badge rounded-pill bg-success bi bi-info-circle-fill" style={{marginLeft:"10px"}}> </span><tool-tip role="tooltip" tip-position="top">Old data: 418-928-5223</tool-tip></label>
                          <input type="text" class="form-control" id="rep-listes-tel" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="collapse multi-collapse" id="multiCollapseInfosequipe">
                  <div class="card card-body">
                    <h2>Team</h2>
                    <div class="row">
                      <div class="col">
                        <div class="mb-3">
                          <label for="equipe-directeurs" class="form-label">Directors<span class="badge rounded-pill bg-success bi bi-info-circle-fill" style={{marginLeft:"10px"}}> </span><tool-tip role="tooltip" tip-position="top">Old data: Jeff Coulombe</tool-tip></label>
                          <input type="text" class="form-control" id="equipe-directeurs" />
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="equipe-rep" class="form-label">Advisor<span class="badge rounded-pill bg-success bi bi-info-circle-fill" style={{marginLeft:"10px"}}> </span><tool-tip role="tooltip" tip-position="top">Old data: aaaaabbbbccccccdddddffffffffadditional data</tool-tip></label>
                          <input type="text" class="form-control" id="equipe-rep" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="collapse multi-collapse" id="multiCollapseInfospromos">
                  <div class="card card-body">
                    <h2>Promotions and Requests</h2>

                    <div class="row">
                      <div class="col">
                        <div class="mb-3">
                          <label for="event-promos" class="form-label">Promotions<span class="badge rounded-pill bg-success bi bi-info-circle-fill" style={{marginLeft:"10px"}}> </span><tool-tip role="tooltip" tip-position="top">Old data: 1% discount, $3000 rebate, -2% interest, loyalty</tool-tip></label>
                          <textarea type="text" class="form-control" id="event-promos"></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="mb-3">
                          <label for="event-comm" class="form-label">Comments<span class="badge rounded-pill bg-success bi bi-info-circle-fill" style={{marginLeft:"10px"}}> </span><tool-tip role="tooltip" tip-position="top">Old data: bla bla bla bla</tool-tip></label>
                          <textarea type="text" class="form-control" id="event-comm"></textarea>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <div class="mb-3">
                          <label for="modifSelection" class="form-label">Changes to selections:<span class="badge rounded-pill bg-success bi bi-info-circle-fill" style={{marginLeft:"10px"}}> </span><tool-tip role="tooltip" tip-position="top">Old data: take truck, take car</tool-tip></label>
                          <textarea type="text" class="form-control" id="modifSelection"></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-8">
                        <div class="mb-3">
                          <label for="modeleAnnPrio" class="form-label">Do you have specific models or years you would like us to prioritize?</label>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="mb-3">
                          <select class="form-select" aria-label="Default select example" id="modeleAnnPrio">
                            <option selected=""></option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="mb-3">
                          <label for="modeleAnnPrioOui" class="form-label">If yes, please let us know here:<span class="badge rounded-pill bg-success bi bi-info-circle-fill" style={{marginLeft:"10px"}}> </span><tool-tip role="tooltip" tip-position="top">Old data: random test data</tool-tip></label>
                          <textarea type="text" class="form-control" id="modeleAnnPrioOui"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div style={{ marginBottom: '10px !important' }} className="row">
                <div style={{ margin: '10', paddingBottom: '10px' }} className="" id="">
                  <div className="card card-body docsection">
                    <h3>Internal Documents</h3>
                    <table className="table table-hover text-center align-middle table-sm">
                      <thead>
                        <tr>
                          <th style={{ color: 'black !important' }} scope="col">#</th>
                          <th style={{ color: 'black !important' }} scope="col">Week</th>
                          <th style={{ color: 'black !important' }} scope="col">Type</th>
                          <th style={{ color: 'black !important' }} scope="col">Creation Date</th>
                          <th style={{ color: 'black !important' }} scope="col">Created by</th>
                          <th style={{ color: 'black !important' }} scope="col"></th>
                          <th style={{ color: 'black !important' }} scope="col"></th>
                        </tr>
                      </thead>
                      <tbody id="searchTableResults" className="">
                        <tr className="result-box">
                          <th style={{ color: 'black !important' }} className="docIdTable" scope="row">1422</th>
                          <td style={{ color: 'black !important' }} className="docWeekTable">500</td>
                          <td style={{ color: 'black !important' }} className="docTypeTable">Preparatory Sheet</td>
                          <td style={{ color: 'black !important' }} className="docCreatedDateTable">2023-07-18_12:31:10 PM</td>
                          <td style={{ color: 'black !important' }} className="docCreatedByTable">MMazraani</td>
                          <td>
                            <a type="button" href="https://drive.google.com/file/d/1adnJs9WNmzdbsFVKUhPKWZVQdfhd3VMC/view?usp=drivesdk" target="_blank" className="btn admin-btn btn-primary doc-download-table" id="">
                              <i className="bi bi-file-earmark-arrow-down"></i> Download
                            </a>
                          </td>
                          <td>
                            <button type="submit" className="btn admin-btn btn-primary doc-send-table" id="FDD1422" data-docidtablecreated="1422">
                              <span id="spinner-doc-send" className="visually-hidden">Loading...</span>
                              <i className="bi bi-send"></i> Send
                            </button>
                          </td>
                        </tr>
                        <tr className="result-box">
                          <th style={{ color: 'black !important' }} className="docIdTable" scope="row">1423</th>
                          <td style={{ color: 'black !important' }} className="docWeekTable">500</td>
                          <td style={{ color: 'black !important' }} className="docTypeTable">Preparatory Sheet</td>
                          <td style={{ color: 'black !important' }} className="docCreatedDateTable">2023-07-18_2:18:10 PM</td>
                          <td style={{ color: 'black !important' }} className="docCreatedByTable">MMazraani</td>
                          <td>
                            <a type="button" href="https://drive.google.com/file/d/1v7J6T8UbDfmkRmRXWyh8jmksH7QJIxLk/view?usp=drivesdk" target="_blank" className="btn admin-btn btn-primary doc-download-table" id="">
                              <i className="bi bi-file-earmark-arrow-down"></i> Download
                            </a>
                          </td>
                          <td>
                            <button type="submit" className="btn admin-btn btn-primary doc-send-table" id="FDD1423" data-docidtablecreated="1423">
                              <span id="spinner-doc-send" className="visually-hidden">Loading...</span>
                              <i className="bi bi-send"></i> Send
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <template id="rowdoctableTemplate">
                      <tr class="result-box">
                        <th class="docIdTable" scope="row"></th>
                        <td /class="docWeekTable"></td>
                        <td class="docTypeTable"></td>
                        <td class="docCreatedDateTable"></td>
                        <td class="docCreatedByTable"></td>
                        <td><a type="button" href="" target="”_blank”" class="btn admin-btn btn-primary doc-download-table" id=""><i class="bi bi-file-earmark-arrow-down"></i> Télécharger</a></td>
                        <td>
                          <button type="submit" class="btn admin-btn btn-primary doc-send-table">
                            <span id="spinner-doc-send" class="visually-hidden">Loading...</span>
                            <i class="bi bi-send"></i> Envoyer </button>
                        </td>
                      </tr>
                    </template>
                  </div>
                </div>
              </div>


            </div>
            <div className="footersec" style={{ marginTop: 10 }}>
              <div className="row">
                <div className="col-sm">
                  <button type="submit" className="btn client-btn btn-success" id="save-client-changes">
                    <i className="bi bi-save"></i> Save
                  </button>
                  <button type="submit" className="btn btn-danger edit-button" id="cancel-client-changes" data-customer-id="920">
                    <i className="bi bi-x-circle"></i> Cancel
                  </button>

                  <div style={{ margin: "0 10px" }} className="btn-group" role="group">
                    <button type="submit" className="btn admin-btn btn-primary" id="prod-client-form-FP">
                      <i className="bi bi-file-earmark-text"></i> Form Pre
                    </button>
                    <button type="submit" className="btn btn-light" id="prod-client-form-DG">
                      <i className="bi bi-file-earmark-text"></i> Form DG
                    </button>
                    <button type="submit" className="btn admin-btn btn-primary" id="prod-client-form-CC">
                      <i className="bi bi-file-earmark-text"></i> Form CA
                    </button>
                  </div>

                  <a type="button" target="_blank" className="btn btn-light drive-button" id="evt-drive-link" href="">
                    <i className="bi bi-folder"></i> Drive
                  </a>
                  <button type="submit" className="btn admin-btn btn-primary" id="convert-to-main" data-customer-id="29">
                    <i className="bi bi-file-earmark-text"></i> Convert
                  </button>
                </div>
              </div>
            </div>

          </div>

          <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: 10000000 }}>
            <div id="liveToast" className="toast alert-success" role="alert" aria-live="assertive" aria-atomic="true">
              <div className="d-flex">
                <div className="toast-body">
                  🎊 🎉 Changes saved
                </div>
                <button type="button" className="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
              </div>
            </div>
            <div id="copieToast" className="toast alert-success" role="alert" aria-live="assertive" aria-atomic="true">
              <div className="d-flex">
                <div className="toast-body">
                  🎊 🎉 Copy successful
                </div>
                <button type="button" className="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
              </div>
            </div>
          </div>
          <div id="myModal" className="modal fade" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div id="creationsuccess">
                  <div className="modal-header">
                    <h3 style={{ color: '#000000' }}>🎊 🎉 Document created</h3>
                    <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                  </div>
                  <div className="modal-body">
                    <p>Your document has been created successfully. You can download or send it by email by clicking the appropriate button below.</p>
                  </div>
                  <div className="modal-footer">
                    <a type="button" href="" target="_blank" className="btn btn-primary" id="doc-download">Download</a>
                    <button type="submit" className="btn btn-primary" id="doc-client-send">
                      <span id="spinner-doc-send" className="visually-hidden">Loading...</span>
                      Send </button>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="myModal2" className="modal fade" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div id="creationsuccess">
                  <div className="modal-header">
                    <h3 style={{ color: '#000000' }}>🎊 🎉 Document created</h3>
                    <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                  </div>
                  <div className="modal-body">
                    <p>Your document has been created successfully. You can download or send it by email by clicking the appropriate button below (please select the recipient first).</p>
                    <div className="row">
                      <div className="mb-3">
                        <select className="form-select" id="conq-email">
                          <option></option>
                          <option>mmazraani@fidgi.ca</option>
                          <option>mazraanimohamad@gmail.com</option>
                          <option>Sylvia.Dimitriou@data-axle.com</option>
                          <option>Sylvia.Dimitriou@data-axle.com</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div style={{ height: '71px' }} className="modal-footer" id="modalfooter">
                    <a type="button" href="" target="_blank" className="btn btn-primary" id="doc-download-conq">Download</a>
                    <button type="submit" className="btn btn-primary" id="doc-send-conq">
                      <span id="spinner-doc-send" className="visually-hidden">Loading...</span>
                      Submit Form </button>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <div className="row" style={{ width: '100%' }}>
                      <div className="alert alert-danger d-flex align-items-center invisible" id="conqerroralert" role="alert">
                        <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlinkHref="#exclamation-triangle-fill"></use></svg>
                        <div id="conqerroralerttext">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="myModal3" className="modal fade" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div id="creationsuccess">
                  <div className="modal-header">
                    <h3 style={{ color: '#000000' }}>✉️ Send the form</h3>
                    <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                  </div>
                  <div className="modal-body">
                    <p style={{ color: '#000000' }}>Please enter the client's email.</p>
                    <div className="row">
                      <div className="mb-3">
                        <label htmlFor="date-but-from" className="form-label">Due Date</label>
                        <input type="date" className="form-control" id="date-but-form" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3">
                        <label style={{ color: '#000000' }} htmlFor="client-form-email" className="form-label">Client's Email</label>
                        <input type="text" className="form-control" id="client-form-email" />
                      </div>
                    </div>
                  </div>
                  <div style={{ height: '71px' }} className="modal-footer" id="modalfooter">
                    <button type="submit" className="btn btn-primary" id="form-send-client">
                      <span id="spinner-doc-send" className="visually-hidden">Loading...</span>
                      Send</button>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <div className="row" style={{ width: '100%' }}>
                      <div className="alert alert-danger d-flex align-items-center invisible" id="clientFormAlert" role="alert">
                        <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlinkHref="#exclamation-triangle-fill"></use></svg>
                        <div id="clientFormAlertText">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="copieEventModal" className="modal fade" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div id="creationsuccess">
                  <div className="modal-header">
                    <h3 style={{ color: '#000000' }}>Event Week</h3>
                    <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                  </div>
                  <div className="modal-body">
                    <p style={{ color: '#000000' }}>Please enter the week number.</p>
                    <div className="row">
                      <div className="mb-3">
                        <label style={{ color: '#000000' }} htmlFor="client-form-email" data-bs-toggle="tooltip" title="" className="form-label">Week: </label>
                        <input type="number" className="form-control" id="event-week-copie" />
                      </div>
                    </div>
                  </div>
                  <div style={{ height: '71px' }} className="modal-footer" id="modalfooter">
                    <button type="submit" data-bs-dismiss="modal" className="btn btn-primary" id="submit-week-client-copie">
                      <span id="spinner-doc-send" className="visually-hidden">Loading...</span>
                      Send</button>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <div className="row" style={{ width: '100%' }}>
                      <div className="alert alert-danger d-flex align-items-center invisible" id="clientFormAlert" role="alert">
                        <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlinkHref="#exclamation-triangle-fill"></use></svg>
                        <div id="clientFormAlertText">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>*/
  );
}

export default EditClientForm;