import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import logo from "./assets/logo.png";
import { toast } from "react-toastify";
import { ApiUrl } from "./ApiUrl";
import translations from "./translation.json";
import { useLanguage } from "./languageContext";

const ChangePasswordModal = ({ setIsLoading, isDarkMode }) => {
  const [loading, setLoadng] = useState(false);
  
  const token = localStorage.getItem("token");
  const { language } = useLanguage();
  const validationSchema = Yup.object().shape({
    // email: Yup.string()
    //   .email("Invalid email address")
    //   .required("Email is required"),
    password: Yup.string()
      .required(
        translations.changePasswordModal.validationText.password.required[
          language
        ]
      )
      .min(
        8,
        translations.changePasswordModal.validationText.password.min[language]
      )
      .matches(
        /[A-Z]/,
        translations.changePasswordModal.validationText.password.match[language]
      ),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        translations.changePasswordModal.validationText.confirmPassword.oneOf[
          language
        ]
      )
      .required(
        translations.changePasswordModal.validationText.password.required[
          language
        ]
      )
      .min(
        8,
        translations.changePasswordModal.validationText.password.min[language]
      )
      .matches(
        /[A-Z]/,
        translations.changePasswordModal.validationText.password.match[language]
      ),
  });


  function handleCloseModal() {
    const closeBtn = document.getElementById("closeChangeModalBtn");
    if (closeBtn) {
      closeBtn.click();
    }
  }

  const handleSubmit = async (values, { resetForm }) => {
    const res = await fetch(ApiUrl + "/api/user/changepassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        password: values.password,
        password_confirmation: values.confirmPassword,
      }),
    });
    const data = await res.json();
    if (data.status === "success") {
      toast.success(data.message);
      handleCloseModal();
    } else {
      toast.error(data.message);
    }
  };
 

  return (
    <div
      id="changePasswordModal"
      class="modal fade"
      tabindex="-1"
      aria-bs-modal="true"
      role="dialog"
      aria-bs-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div id="creationsuccess">
            {/* <div class="modal-body"> */}
            <div className="">
              <main className="">
                <Formik
                  initialValues={{
                    // email: "",
                    password: "",
                    confirmPassword: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  <Form>
                    {/* <div className="d-flex justify-content-center">
                        <Link to="/">
                          <img
                            className="mb-4 "
                            width="100px"
                            src={logo}
                            alt=""
                          />
                        </Link>
                      </div> */}
                    <div class="modal-header">
                      {" "}
                      <h3>
                        {translations.topAndsidebar.changePassword[language]}
                      </h3>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        id="closeChangeModalBtn"
                        style={{
                          filter: isDarkMode ? "invert(1)" : "none", // Invert the color in dark mode
                          color: isDarkMode
                            ? "#ffffff"
                            : "rgb(255, 255, 255)",
                        }}
                      ></button>
                    </div>
                    <div class="modal-body">
                      <div
                        className={`form-floating ${
                          isDarkMode && isDarkMode ? "dark-mode" : ""
                        }`}
                      >
                        <Field
                          type="password"
                          name="password"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode && isDarkMode ? "transparent" : "",
                            color:isDarkMode && isDarkMode ? "#ffffff" : "#000000",
                          }}
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="error text-danger"
                        />
                        <label
                          htmlFor="floatingPassword"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                          }}
                        >
                          {
                            translations.changePasswordModal.newPassword[
                              language
                            ]
                          }
                        </label>
                      </div>
                      <div
                        className={`form-floating mt-3 ${
                          isDarkMode ? "dark-mode" : ""
                        }`}
                      >
                        <Field
                          type="password"
                          name="confirmPassword"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                          }}
                        />
                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className="error text-danger"
                        />
                        <label
                          htmlFor="floatingPassword"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                          }}
                        >
                          {
                            translations.changePasswordModal.confirmPassword[
                              language
                            ]
                          }
                        </label>
                      </div>
                    </div>
                    <div class="modal-footer" id="modalfooter">
                      <button
                        className="w-100 admin-btn btn-lg btn-primary mt-3"
                        type="submit"
                        disabled={loading}
                      >
                        {translations.topAndsidebar.changePassword[language]}
                      </button>
                    </div>
                  </Form>
                </Formik>
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
